<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <!-- Header row with title, items per page, search input, and create button -->
      <div class="d-flex justify-content-between align-items-center">
        <!-- Models Title -->
        <h6 class="mb-0">Models</h6>

        <!-- Items per page select -->
        <div class="d-flex align-items-center">
          <label for="itemsPerPage" class="mr-2 mb-0">Items per page</label>
          <div class="form-group mb-0 w-auto">
            <select id="itemsPerPage" class="form-control" v-model="perPage" @change="onItemsPerPageChange">
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
              <option :value="Number.MAX_VALUE">All</option>
            </select>
          </div>
        </div>

        <!-- Search input -->
        <div class="form-group mb-0 w-25">
          <label for="search" class="d-none">Search</label>
          <input type="text" id="search" v-model="searchQuery" class="form-control"
            placeholder="Search by name, category..." @input="filterModels" />
        </div>

        <!-- Create button -->
        <RouterLink to="/models/add" class="btn btn-success ml-2">
          Create
        </RouterLink>
      </div>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <!-- Data Table -->
      <data-table :data-list="paginatedModels" :headerList="headerList">
        <template v-slot:action="slotProps">
          <router-link :to="{ path: `/models/edit/${slotProps.row.name}` }" class="btn btn-outline-dark px-2 py-1">
            <i class="fa fa-pen"></i>
          </router-link>
          <button class="btn btn-outline-danger px-2 py-1 mx-1" @click="onDelete(slotProps.row.id)">
            <i class="fa fa-trash"></i>
          </button>
        </template>
      </data-table>

      <!-- Pagination -->
      <div class="d-flex justify-content-center mt-3 gap-3 mr-3">
        <b-pagination v-if="perPage !== Number.MAX_VALUE" v-model="currentPage" :total-rows="filteredModels.length"
          :per-page="perPage" first-text="First" prev-text="Prev" next-text="Next" last-text="Last"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/DataTable.vue";
import { RouterLink } from "vue-router";
import { mapGetters } from "vuex";

const headerList = [
  { name: "Sr.no", id: "id" },
  { name: "Name", id: "name" },
  { name: "Category", id: "modelCategory.name" },
  { name: "Action", id: "action" },
];

export default {
  name: "Models",
  components: {
    DataTable,
    RouterLink,
  },
  data() {
    return {
      headerList: headerList,
      currentPage: 1, // Current page
      perPage: 10, // Rows per page
      searchQuery: "", // Search query
      filteredModels: [], // Filtered models based on search
    };
  },
  computed: {
    ...mapGetters("models", ["models", "isLoading"]),

    // Calculate total rows from the filtered models list
    totalRows() {
      return this.filteredModels.length;
    },

    // Get paginated filtered models for the current page
    paginatedModels() {
      if (this.perPage === Number.MAX_VALUE) {
        return this.filteredModels; // Show all models if "All" is selected
      }
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.filteredModels.slice(start, end);
    },
  },
  created() {
    this.$store.dispatch("models/fetchModels", null, {
      root: true,
    });
  },
  methods: {
    onDelete(id) {
      this.$swal({
        title: `Are you sure you want to delete Model?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("models/delete", id);
          this.$swal("Deleted!", `Model has been deleted.`, "success");
        }
      });
    },

    // Method to handle changes in the "items per page" selection

    onItemsPerPageChange() {
      this.currentPage = 1; // Reset to first page when perPage changes
    },

    // Method to filter the models based on search query
    filterModels() {
      if (!this.searchQuery.trim()) {
        // If search is empty, show all models
        this.filteredModels = this.models;
      } else {
        // Filter models based on name or category
        this.filteredModels = this.models.filter(model => {
          const query = this.searchQuery.toLowerCase();
          return (
            model.name.toLowerCase().includes(query) ||
            model.modelCategory.name.toLowerCase().includes(query)
          );
        });
      }
      // Reset to first page when filtering
      this.currentPage = 1;
    },
  },
  watch: {
    // Watch for changes in models data and filter
    models(newModels) {
      this.filteredModels = newModels;
      this.filterModels(); // Apply the filter when models are fetched
    },
  },
};
</script>
