<template>
  <div class="card my-4 mx-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-content-center"
    >
      <h6>Feature Services</h6>
      <RouterLink to="/feature-services/add" class="btn btn-success"
        >Create</RouterLink
      >
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <!-- Data Table -->
      <data-table :data-list="paginatedServices" :headerList="headerList">
        <template v-slot:action="slotProps">
          <router-link
            :to="{ path: `/feature-services/edit/${slotProps.row.id}` }"
            class="btn btn-outline-dark px-2 py-1"
          >
            <i className="fa fa-pen"></i>
          </router-link>
          <button
            class="btn btn-outline-danger px-2 py-1 mx-1"
            @click="onDelete(slotProps.row.id)"
          >
            <i className="fa fa-trash"></i>
          </button>
        </template>
      </data-table>

      <!-- Pagination -->
      <div class="d-flex justify-content-center mt-3 gap-3 mr-3">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/DataTable.vue";
import { RouterLink } from "vue-router";
import { mapGetters } from "vuex";

const headerList = [
  { name: "Sr.no", id: "id" },
  { name: "Feature Service", id: "title" },
  { name: "Action", id: "action" },
];

export default {
  name: "Feature Services",
  components: {
    DataTable,
    RouterLink,
  },
  data() {
    return {
      headerList: headerList,
      currentPage: 1, // Current page
      perPage: 10, // Rows per page
    };
  },
  created() {
    this.$store.dispatch("featureServices/fetchFeatureServices", null, {
      root: true,
    });
  },
  computed: {
    ...mapGetters("featureServices", ["services", "isLoading"]),

    // Total rows for the pagination
    totalRows() {
      return this.services.length;
    },

    // Paginated services data for the current page
    paginatedServices() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.services.slice(start, end);
    },
  },
  methods: {
    onDelete(id) {
      this.$swal({
        title: `Are you sure you want to delete Feature Service?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("featureServices/delete", id);
          this.$swal(
            "Deleted!",
            `Feature Service has been deleted.`,
            "success"
          );
        }
      });
    },
  },
};
</script>




<!-- <template>
  <div class="card my-4 mx-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-content-center"
    >
      <h6>Feature Services</h6>
      <RouterLink to="/feature-services/add" class="btn btn-success"
        >Create</RouterLink
      >
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <data-table :data-list="services" :headerList="headerList">
        <template v-slot:action="slotProps">
          <router-link
            :to="{ path: `/feature-services/edit/${slotProps.row.id}` }"
            class="btn btn-outline-dark px-2 py-1"
          >
            <i className="fa fa-pen"></i>
          </router-link>
          <button
            class="btn btn-outline-danger px-2 py-1 mx-1"
            @click="onDelete(slotProps.row.id)"
          >
            <i className="fa fa-trash"></i>
          </button>
        </template>
      </data-table>
    </div>
  </div>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import { RouterLink } from "vue-router";
import { mapGetters } from "vuex";
const headerList = [
  { name: "Sr.no", id: "id" },
  { name: "Feature Service", id: "title" },
  { name: "Action", id: "action" },
];

export default {
  name: "Feature Services",
  components: {
    DataTable,
    RouterLink,
  },
  data() {
    return {
      headerList: headerList,
    };
  },
  created() {
    this.$store.dispatch("featureServices/fetchFeatureServices", null, {
      root: true,
    });
  },
  computed: {
    ...mapGetters("featureServices", ["services", "isLoading"]),
  },
  mounted() {},
  methods: {
    onDelete(id) {
      this.$swal({
        title: `Are you sure you want to delete Feature Service?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("featureServices/delete", id);
          this.$swal(
            "Deleted!",
            `Feature Service has been deleted.`,
            "success"
          );
        }
      });
    },
  },
};
</script> -->
