<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <!-- Header row with title, items per page label + dropdown, search input, and export buttons -->
      <div class="d-flex justify-content-between align-items-center">
        <!-- Title -->
        <h6 class="mb-0">Test Drive</h6>

        <!-- Items per page label + dropdown -->
        <div class="d-flex align-items-center">
          <label for="itemsPerPage" class="mr-2 mb-0">Items per page</label>
          <div class="form-group mb-0 w-auto">
            <select id="itemsPerPage" class="form-control" v-model="perPage" @change="onItemsPerPageChange">
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
              <option :value="Number.MAX_VALUE">All</option>
            </select>
          </div>
        </div>

        <!-- Search input -->
        <div class="form-group mb-0 w-25">
          <label for="search" class="d-none">Search</label>
          <input type="text" id="search" v-model="searchQuery" class="form-control"
            placeholder="Search by name, country, email..." @input="filterTestDrives" />
        </div>

        <!-- Export Buttons -->
        <div class="d-flex gap-2">
          <button class="btn btn-primary" @click="handleExportCSV">Export to CSV</button>
          <button class="btn btn-success" @click="handleExportPDF">Export to PDF</button>
        </div>
      </div>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <!-- Data Table -->
      <data-table :data-list="paginatedTestDrives" :headerList="headerList"></data-table>

      <!-- Pagination -->
      <div class="d-flex justify-content-center mt-3 gap-3 mr-3">
        <b-pagination v-if="perPage !== Number.MAX_VALUE && filteredTestDrives && filteredTestDrives.length > 0"
          v-model="currentPage" :total-rows="filteredTestDrives.length" :per-page="perPage" first-text="First"
          prev-text="Prev" next-text="Next" last-text="Last"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from '../../components/DataTable.vue';
import { exportToCSV, exportToPDF } from '../../exportUtils';

const headerList = [
  { name: 'Sr.no', id: 'id' },
  { name: 'Country', id: 'country' },
  { name: 'Gender', id: 'gender' },
  { name: 'Name', id: 'name' },
  { name: 'Phone Number', id: 'phoneNumber' },
  { name: 'Email', id: 'email' },
  { name: 'Created Date', id: 'createdAt' },
];

export default {
  name: 'Test Drive',
  components: {
    DataTable,
  },
  data() {
    return {
      headerList: headerList,
      currentPage: 1, // Current page
      perPage: 10, // Rows per page
      searchQuery: '', // Search query string
    };
  },
  created() {
    this.$store.dispatch('testDrive/fetchtestDrive', null, { root: true });
  },
  computed: {
    // Get the list of test drives from the store
    allTestDrives() {
      return this.$store.state.testDrive.testdrive || [];
    },

    // Filtered test drives based on search query
    filteredTestDrives() {
      if (!this.searchQuery.trim()) {
        return this.allTestDrives;
      }
      const query = this.searchQuery.toLowerCase();
      return this.allTestDrives.filter(testDrive => {
        const name = (testDrive.name || '').toLowerCase();
        const country = (testDrive.country || '').toLowerCase();
        const email = (testDrive.email || '').toLowerCase();
        return name.includes(query) || country.includes(query) || email.includes(query);
      });
    },

    // Paginated data for current page
    paginatedTestDrives() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.filteredTestDrives.slice(start, end);
    },
  },
  methods: {
    // Handle CSV export
    handleExportCSV() {
      exportToCSV(this.paginatedTestDrives, this.headerList);
    },

    // Handle PDF export
    handleExportPDF() {
      exportToPDF(this.paginatedTestDrives, this.headerList);
    },

    // Handle items per page change
    onItemsPerPageChange() {
      this.currentPage = 1; // Reset to first page on items per page change
    },
  },
};
</script>
