<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <!-- Header row with title, items per page, search input, and export buttons -->
      <div class="d-flex justify-content-between align-items-center">
        <!-- Models Title -->
        <h6 class="mb-0">Contact Us Inquiries</h6>

        <!-- Items per page select -->
        <div class="d-flex align-items-center">
          <label for="itemsPerPage" class="mr-2 mb-0">Items per page</label>
          <div class="form-group mb-0 w-auto">
            <select id="itemsPerPage" class="form-control" v-model="perPage" @change="onItemsPerPageChange">
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
              <option :value="Number.MAX_VALUE">All</option>
            </select>
          </div>
        </div>

        <!-- Search input -->
        <div class="form-group mb-0 w-25">
          <label for="search" class="d-none">Search</label>
          <input type="text" id="search" v-model="searchQuery" class="form-control"
            placeholder="Search by name, email..." @input="filterSubscribes" />
        </div>

        <!-- Export Buttons (align next to each other) -->
        <div class="d-flex gap-2 ml-2">
          <button class="btn btn-primary" @click="handleExportCSV">Export to CSV</button>
          <button class="btn btn-success" @click="handleExportPDF">Export to PDF</button>
        </div>
      </div>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <!-- Data Table -->
      <data-table :data-list="paginatedContacts" :headerList="headerList" />

      <!-- Pagination -->
      <div class="d-flex justify-content-center mt-3 gap-3 mr-3">
        <b-pagination  v-if="perPage !== Number.MAX_VALUE"
          v-model="currentPage" 
          :total-rows="totalRows" 
          :per-page="perPage"
          first-text="First" 
          prev-text="Prev" 
          next-text="Next" 
          last-text="Last" 
        />
      </div>
    </div>
  </div>
</template>
<!-- 
<script>
import DataTable from '../../components/DataTable.vue';
import { exportToCSV, exportToPDF } from '../../exportUtils';

const headerList = [
  { name: 'Sr.no', id: 'id' },
  { name: 'First Name', id: 'firstName' },
  { name: 'Last Name', id: 'lastName' },
  { name: 'Email', id: 'email' },
  { name: 'Media Name', id: 'mediaName' },
  { name: 'Media Introduction', id: 'mediaIntroduction' },
];

export default {
  name: 'ContactUsInquiries',
  components: {
    DataTable,
  },
  data() {
    return {
      headerList: headerList,
      currentPage: 1, // Current page
      perPage: 10, // Rows per page
    };
  },
  computed: {
    // Calculate total rows from the contacts list
    totalRows() {
      return this.$store.state.contactus.contacts.length;
    },

    // Get paginated contacts for the current page
    paginatedContacts() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.$store.state.contactus.contacts.slice(start, end);
    },
  },
  methods: {
    handleExportCSV() {
      // Corrected variable name (using paginatedContacts)
      exportToCSV(this.paginatedContacts, this.headerList);
    },

    handleExportPDF() {
      // Corrected variable name (using paginatedContacts)
      exportToPDF(this.paginatedContacts, this.headerList);
    },
  },
  created() {
    this.$store.dispatch('contactus/fetchContacts', null, { root: true });
  },
};
</script> -->
<script>
import DataTable from '../../components/DataTable.vue';
import { exportToCSV, exportToPDF } from '../../exportUtils';

const headerList = [
  { name: 'Sr.no', id: 'id' },
  { name: 'First Name', id: 'firstName' },
  { name: 'Last Name', id: 'lastName' },
  { name: 'Email', id: 'email' },
  { name: 'Media Name', id: 'mediaName' },
  { name: 'Media Introduction', id: 'mediaIntroduction' },
];

export default {
  name: 'ContactUsInquiries',
  components: {
    DataTable,
  },
  data() {
    return {
      headerList: headerList,
      currentPage: 1, // Current page
      perPage: 10, // Rows per page
      searchQuery: '', // Search query
      filteredContacts: [], // Filtered contacts after search
    };
  },
  computed: {
    // Calculate total rows from the filtered contacts list
    totalRows() {
      return this.filteredContacts.length;
    },

    // Get paginated contacts for the current page
    paginatedContacts() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.filteredContacts.slice(start, end);
    },
  },
  created() {
    this.$store.dispatch('contactus/fetchContacts', null, { root: true });
    
    // Set initial filtered contacts to all contacts
    this.$nextTick(() => {
      this.filteredContacts = this.$store.state.contactus.contacts;
    });
  },
  watch: {
    // Watch for changes in searchQuery and filter accordingly
    searchQuery() {
      this.filterContacts(); // Re-filter when search query changes
    },

    // Watch for changes in contacts data and reset filtered contacts
    '$store.state.contactus.contacts'(newContacts) {
      this.filteredContacts = newContacts;
      this.filterContacts(); // Re-filter after subscribing to changes
    }
  },
  methods: {
    // Method to handle search filtering
    filterContacts() {
      if (!this.searchQuery.trim()) {
        // If no search query, show all contacts
        this.filteredContacts = this.$store.state.contactus.contacts;
      } else {
        // Filter based on the search query
        const query = this.searchQuery.toLowerCase();
        this.filteredContacts = this.$store.state.contactus.contacts.filter(contact => {
          const fullName = `${contact.firstName} ${contact.lastName}`.toLowerCase();
          const isMatch =
            fullName.includes(query) ||
            contact.email.toLowerCase().includes(query) ||
            contact.mediaName.toLowerCase().includes(query) ||
            contact.mediaIntroduction.toLowerCase().includes(query);
          return isMatch;
        });
      }

      // Reset pagination to the first page after filtering
      this.currentPage = 1;
    },

    // Handle "Items per page" changes
    onItemsPerPageChange() {
        this.currentPage = 1;
    },

    handleExportCSV() {
      // Use the external exportToCSV method
      exportToCSV(this.paginatedContacts, this.headerList);
    },

    handleExportPDF() {
      // Use the external exportToPDF method
      exportToPDF(this.paginatedContacts, this.headerList);
    }
  }
};
</script>

<style scoped>
.card-header {
  justify-content: space-between;
  align-items: center;
}

.d-flex {
  display: flex;
}

.gap-2 {
  gap: 10px;
}

.ml-2 {
  margin-left: 10px;
}

/* Adjustments for export buttons alignment */
.card-header .d-flex > .form-group {
  margin-right: 15px;
}

.card-body {
  padding: 0;
}

/* Ensure search and export buttons align without extra spacing */
.card-header .form-group {
  margin-bottom: 0;
}

.card-header .d-flex .btn {
  padding: 0.5rem 1rem;
  margin-left: 5px;
}

.card-header .d-flex .form-group input {
  width: 220px; /* Adjust to make input smaller */
}
</style>