<template>
  <div class="table-responsive p-3">
    <table class="table align-items-center mb-0 text-center">
      <thead v-if="headerList" >
        <tr>
          <th v-for="(header, index) in headerList" :key="index" scope="col">
            {{ header.name }}
          </th>
        </tr>
      </thead>
      <tbody v-if="dataList == 0" >
        <tr>
          <td :colspan="headerList.length">
            <h5>No Record Found</h5>
          </td>
        </tr>
      </tbody>
      <tbody v-else >
        <tr v-for="(data, i) in dataList" :key="i">
          <td v-for="(header, index) in headerList" :key="index">
            <slot :name="header.id" :row="data" v-if="header.id == 'id'">
              {{ i + 1 }}
            </slot>
            <slot :name="header.id" :row="data" v-else>
              <div>
                {{ get(data, header.id, null) }}
              </div>
            </slot>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import _ from "lodash";

export default {
  name: "Data Table",
  data() {
    return {
      get: _.get,
    };
  },
  props: {
    headerList: {
      type: Array, // array of { name, isSortable, id }
      required: false,
      default: null,
    },
    dataList: {
      type: Array,
      required: false,
      default: null,
    },
  },
};
</script>
