<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Edit Overview CMS</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form as="form" @submit="onSubmit" class="form" :initial-values="initialData" :validation-schema="schema"
          v-slot="{ errors }" enctype="multipart/form-data" v-if="overviewObj">
          <div class="form-group row">
            <div class="col-md-6">
              <label for="banner_title">Overview Banner Title</label>
              <Field name="banner_title" type="text" class="form-control" v-model="overviewObj.banner_title"
                :class="{ 'is-invalid': errors.banner_title }" />
              <div class="invalid-feedback">{{ errors.banner_title }}</div>
            </div>
            <div class="col-md-6">
              <label for="banner_title_ar">Overview Arabic Banner Title</label>
              <Field name="banner_title_ar" type="text" class="form-control" v-model="overviewObj.banner_title_ar"
                :class="{ 'is-invalid': errors.banner_title_ar }" />
              <div class="invalid-feedback">{{ errors.banner_title_ar }}</div>
            </div>
          </div>
          <div class="form-group my-2">
            <label for="banner_description">Overview Banner Description</label>
            <quill-editor v-model:content="overviewObj.banner_description" class="mb-3" id="rich-text" rows="20"
              ref="quill" @change="onChange($event)">
            </quill-editor>
          </div>
          <div class="form-group my-2">
            <label for="banner_description_ar">Overview Arabic Banner Description</label>
            <quill-editor v-model:content="overviewObj.banner_description_ar" class="mb-3" id="rich-text-ar" rows="20"
              ref="quill" @change="onChangeAR($event)">
            </quill-editor>
          </div>
          <div class="form-group">
            <button @click.prevent="() => this.$refs.bannerImageRef.click()" class="btn btn-outline-danger">
              OverView Banner Image Upload
            </button>
            <input type="file" ref="bannerImageRef" @change="event => onFileChange(event, 'banner_image')"
              accept="image/*" style="display: none" />
            <div v-if="banner_imageData">
              <h5>Preview Overview Banner Image:</h5>
              <img :src="banner_imageData" alt="Image Preview" height="200" width="230" />
            </div>
            <div v-if="overviewObj && !banner_imageData">
              <h5>Preview Overview Banner Image:</h5>
              <img :src="imageApi + overviewObj.banner_image" alt="Image Preview" height="200" width="230" />
            </div>
          </div>
          <div class="my-4">
            <h4>Overview Section 1</h4>
            <div class="form-group">
              <button @click.prevent="() => this.$refs.image1Ref.click()" class="btn btn-outline-danger">
                OverView Section 1 Image Upload
              </button>
              <input type="file" ref="image1Ref" @change="event => onFileChange(event, 'section1_image')"
                accept="image/*" style="display: none" />
              <div v-if="section1_imageData">
                <h5>Preview Section 1 Image:</h5>
                <img :src="section1_imageData" alt="Image Preview" height="200" width="230" />
              </div>
              <div v-if="overviewObj && !section1_imageData">
                <h5>Preview Section 1 Image:</h5>
                <img :src="imageApi + overviewObj.section1_image" alt="Image Preview" height="200" width="230" />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section1_title">Section 1 Title</label>
                <Field name="section1_title" type="text" class="form-control" v-model="overviewObj.section1_title"
                  :class="{ 'is-invalid': errors.section1_title }" />
                <div class="invalid-feedback">{{ errors.section1_title }}</div>
              </div>
              <div class="col-md-6">
                <label for="section1_title_ar">Section 1 Arabic Title</label>
                <Field name="section1_title_ar" type="text" class="form-control" v-model="overviewObj.section1_title_ar"
                  :class="{ 'is-invalid': errors.section1_title_ar }" />
                <div class="invalid-feedback">{{ errors.section1_title_ar }}</div>
              </div>
            </div>
            <div class="form-group my-2">
              <label for="section1_description">Overview Section 1 Description</label>
              <quill-editor v-model:content="overviewObj.section1_description" class="mb-3" id="rich-text" rows="20"
                ref="quill" @change="onChangeSection1($event)">
              </quill-editor>
            </div>
            <div class="form-group my-2">
              <label for="section1_description_ar">Overview Arabic Section 1 Description</label>
              <quill-editor v-model:content="overviewObj.section1_description_ar" class="mb-3" id="rich-text-ar"
                rows="20" ref="quill" @change="onChangeARSection1($event)">
              </quill-editor>
            </div>
          </div>

          <div class="my-4">
            <h4>Section 2</h4>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section2_title">Section 2 Title</label>
                <Field name="section2_title" type="text" class="form-control" v-model="overviewObj.section2_title"
                  :class="{ 'is-invalid': errors.section2_title }" />
                <div class="invalid-feedback">{{ errors.section2_title }}</div>
              </div>
              <div class="col-md-6">
                <label for="section2_title_ar">Arabic Section 2 Title</label>
                <Field name="section2_title_ar" type="text" class="form-control" v-model="overviewObj.section2_title_ar"
                  :class="{ 'is-invalid': errors.section2_title_ar }" />
                <div class="invalid-feedback">{{ errors.section2_title_ar }}</div>
              </div>
            </div>
            <div class="form-group my-2">
              <label for="section2_description">Section 2 Description</label>
              <quill-editor v-model:content="overviewObj.section2_description" class="mb-3" id="rich-text" rows="20"
                ref="quill" @change="onChangesection2_description($event)">
              </quill-editor>
            </div>
            <div class="form-group my-2">
              <label for="section2_description_ar">Arabic Section 2 Description</label>
              <quill-editor v-model:content="overviewObj.section2_description_ar" class="mb-3" id="rich-text-ar"
                rows="20" ref="quill" @change="onChangesection2_description_ar($event)">
              </quill-editor>
            </div>
            <div class="form-group">
              <button @click.prevent="() => this.$refs.image2Ref.click()" class="btn btn-outline-danger">
                Section 2 Image Upload
              </button>
              <input type="file" ref="image2Ref" @change="event => onFileChange(event, 'section2_image')"
                accept="image/*" style="display: none" />
              <div v-if="section2_imageData">
                <h5>Preview Section 2 Image:</h5>
                <img :src="section2_imageData" alt="Image Preview" height="200" width="230" />
              </div>
              <div v-if="overviewObj && !section2_imageData">
                <h5>Preview Section 2 Image:</h5>
                <img :src="imageApi + overviewObj.section2_image" alt="Image Preview" height="200" width="230" />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section2_image_title1">Section 2 image Title 1</label>
                <Field name="section2_image_title1" type="text" class="form-control"
                  v-model="overviewObj.section2_image_title1" :class="{ 'is-invalid': errors.section2_image_title1 }" />
                <div class="invalid-feedback">{{ errors.section2_image_title1 }}</div>
              </div>
              <div class="col-md-6">
                <label for="section2_image_title1_ar">Arabic Section 2 image Title 1</label>
                <Field name="section2_image_title1_ar" type="text" class="form-control"
                  v-model="overviewObj.section2_image_title1_ar"
                  :class="{ 'is-invalid': errors.section2_image_title1_ar }" />
                <div class="invalid-feedback">{{ errors.section2_image_title1_ar }}</div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section2_image_title2">Section 2 image Title 2</label>
                <Field name="section2_image_title2" type="text" class="form-control"
                  v-model="overviewObj.section2_image_title2" :class="{ 'is-invalid': errors.section2_image_title2 }" />
                <div class="invalid-feedback">{{ errors.section2_image_title2 }}</div>
              </div>
              <div class="col-md-6">
                <label for="section2_image_title2_ar">Arabic Section 2 image Title 2</label>
                <Field name="section2_image_title2_ar" type="text" class="form-control"
                  v-model="overviewObj.section2_image_title2_ar"
                  :class="{ 'is-invalid': errors.section2_image_title2_ar }" />
                <div class="invalid-feedback">{{ errors.section2_image_title2_ar }}</div>
              </div>
            </div>
          </div>
          <div class="my-4">
            <h4>Section 3</h4>

            <div class="form-group">
              <button @click.prevent="() => this.$refs.image3Ref.click()" class="btn btn-outline-danger">
                Section 3 Image Upload
              </button>
              <input type="file" ref="image3Ref" @change="event => onFileChange(event, 'section3_image')"
                accept="image/*" style="display: none" />
              <div v-if="section3_imageData">
                <h5>Preview Section 3 Image:</h5>
                <img :src="section3_imageData" alt="Image Preview" height="200" width="230" />
              </div>
              <div v-if="overviewObj && !section3_imageData">
                <h5>Preview Section 3 Image:</h5>
                <img :src="imageApi + overviewObj.section3_image" alt="Image Preview" height="200" width="230" />
              </div>
            </div>

            <div class="form-group row">
              <div class="col-md-6">
                <label for="section3_title">Section 3 Title 1</label>
                <quill-editor v-model:content="overviewObj.section3_title1" class="mb-3" id="rich-text-ar" rows="20"
                  ref="quill" @change="onChangesection3_title1($event)">
                </quill-editor>
                <div class="invalid-feedback">{{ errors.section3_title }}</div>
              </div>
              <div class="col-md-6">
                <label for="section3_title_ar">Arabic Section 3 Title</label>
                <quill-editor v-model:content="overviewObj.section3_title1_ar" class="mb-3" id="rich-text-ar" rows="20"
                  ref="quill" @change="onChangesection3_title1_ar($event)"></quill-editor>
                <div class="invalid-feedback">{{ errors.section3_title_ar }}</div>
              </div>
            </div>
            <div class="form-group my-2">
              <label for="section3_description">Section 3 Description 1</label>
              <quill-editor v-model:content="overviewObj.section3_description1" class="mb-3" id="rich-text" rows="20"
                ref="quill" @change="onChangesection3_description1($event)">
              </quill-editor>
            </div>
            <div class="form-group my-2">
              <label for="section3_description_ar">Arabic Section 3 Description</label>
              <quill-editor v-model:content="overviewObj.section3_description1_ar" class="mb-3" id="rich-text-ar"
                rows="20" ref="quill" @change="onChangesection3_description1_ar($event)">
              </quill-editor>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="section3_title2">Section 3 Title 1</label>
                <quill-editor v-model:content="overviewObj.section3_title2" class="mb-3" id="rich-text-ar" rows="20"
                  ref="quill" @change="onChangesection3_title2($event)">
                </quill-editor>
                <div class="invalid-feedback">{{ errors.section3_title2 }}</div>
              </div>
              <div class="col-md-6">
                <label for="section3_title2_ar">Arabic Section 3 Title 1</label>
                <quill-editor v-model:content="overviewObj.section3_title2_ar" class="mb-3" id="rich-text-ar" rows="20"
                  ref="quill" @change="onChangesection3_title2_ar($event)"></quill-editor>
                <div class="invalid-feedback">{{ errors.section3_title2_ar }}</div>
              </div>
            </div>
            <div class="form-group my-2">
              <label for="section3_description2">Section 3 Description 2</label>
              <quill-editor v-model:content="overviewObj.section3_description2" class="mb-3" id="rich-text" rows="20"
                ref="quill" @change="onChangesection3_description2($event)">
              </quill-editor>
            </div>
            <div class="form-group my-2">
              <label for="section3_description2_ar">Arabic Section 3 Description 2</label>
              <quill-editor v-model:content="overviewObj.section3_description2_ar" class="mb-3" id="rich-text-ar"
                rows="20" ref="quill" @change="onChangesection3_description2_ar($event)">
              </quill-editor>
            </div>
            <div class="form-group my-2">
              <label for="section3_description3">Section 3 Description 3</label>
              <quill-editor v-model:content="overviewObj.section3_description3" class="mb-3" id="rich-text" rows="20"
                ref="quill" @change="onChangesection3_description3($event)">
              </quill-editor>
            </div>
            <div class="form-group my-2">
              <label for="section3_description3_ar">Arabic Section 3 Description 3</label>
              <quill-editor v-model:content="overviewObj.section3_description3_ar" class="mb-3" id="rich-text-ar"
                rows="20" ref="quill" @change="onChangesection3_description3_ar($event)">
              </quill-editor>
            </div>
          </div>
          <button type="submit" class="btn btn-danger my-2">Submit</button>
          <button type="button" @click="handleCancel" class="btn btn-outline-success my-2 cancel-btn">
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import imageApi from "../../api/imageapi";
import _ from "lodash";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "Overview",
  components: {
    Form,
    Field,
    quillEditor,
  },
  data() {
    const { setFieldValue } = useForm();

    const schema = yup.object().shape({
      // banner
      banner_title: yup.string().required("Overview Banner Title is Required"),
      banner_title_ar: yup
        .string()
        .required("Overview Banner Arabic Title is Required"),
     
    });

    const initialData = {
      banner_title: "",
      banner_title_ar: "",
      banner_description: "",
      banner_description_ar: "",
      banner_image: "",
      section1_image: "",
      section1_title: "",
      section1_title_ar: "",
      section1_description: "",
      section1_description_ar: "",
      section2_image: "",
      section3_image: "",
      section2_image_title1: "",
      section2_image_title1_ar: "",
      section2_image_title2: "",
      section2_image_title2_ar: "",
      section2_title: "",
      section2_title_ar: "",
      section2_description: "",
      section2_description_ar: "",
      section3_title1: "",
      section3_title1_ar: "",
      section3_description1: "",
      section3_description1_ar: "",
      section3_title2: "",
      section3_title2_ar: "",
      section3_description2: "",
      section3_description2_ar: "",
      section3_description3: "",
      section3_description3_ar: "",
    };

    return {
      imageApi: imageApi,
      banner_description: "",
      banner_description_ar: "",
      section1_description: "",
      section1_description_ar: "",
      banner_image: null,
      section2_image: null,
      section3_image: null,
      banner_title: "",
      banner_title_ar: "",
      section1_image: "",
      section1_title: "",
      section1_title_ar: "",
      section2_image_title1: "",
      section2_image_title1_ar: "",
      section2_image_title2: "",
      section2_image_title2_ar: "",
      section2_title: "",
      section2_title_ar: "",
      section2_description: "",
      section2_description_ar: "",
      section3_title1: "",
      section3_title1_ar: "",
      section3_description1: "",
      section3_description1_ar: "",
      section3_title2: "",
      section3_title2_ar: "",
      section3_description2: "",
      section3_description2_ar: "",
      section3_description3: "",
      section3_description3_ar: "",
      schema,
      initialData,
      setFieldValue,
    };
  },
  computed: {
    ...mapGetters("overView", ["overviewObj", "isLoading"]),
  },
  async mounted() {
    await this.fetchDetail();
    let initData = this.overviewObj ? _.clone(this.overviewObj) : {};
    this.initialData = this.overviewObj
      ? {
        ...initData,
      }
      : this.initialData;
    this.banner_description = this.overviewObj.banner_description;
    this.banner_description_ar = this.overviewObj.banner_description_ar;
    this.banner_title = this.overviewObj.banner_title;
    this.banner_title_ar = this.overviewObj.banner_title_ar;
    this.section1_title = this.overviewObj.section1_title;
    this.section1_title_ar = this.overviewObj.section1_title_ar;
    this.section1_description = this.overviewObj.section1_description;
    this.section1_description_ar = this.overviewObj.section1_description_ar;
    this.section2_image_title1 = this.overviewObj.section2_image_title1;
    this.section2_image_title1_ar = this.overviewObj.section2_image_title1_ar;
    this.section2_image_title2 = this.overviewObj.section2_image_title2;
    this.section2_image_title2_ar = this.overviewObj.section2_image_title2_ar;
    this.section2_title = this.overviewObj.section2_title;
    this.section2_title_ar = this.overviewObj.section2_title_ar;
    this.section2_description = this.overviewObj.section2_description;
    this.section2_description_ar = this.overviewObj.section2_description_ar;
    this.section3_title1 = this.overviewObj.section3_title1;
    this.section3_title1_ar = this.overviewObj.section3_title1_ar;
    this.section3_description1 = this.overviewObj.section3_description1;
    this.section3_description1_ar = this.overviewObj.section3_description1_ar;
    this.section3_title2 = this.overviewObj.section3_title2;
    this.section3_title2_ar = this.overviewObj.section3_title2_ar;
    this.section3_description2 = this.overviewObj.section3_description2;
    this.section3_description2_ar = this.overviewObj.section3_description2_ar;
    this.section3_description3 = this.overviewObj.section3_description3;
    this.section3_description3_ar = this.overviewObj.section3_description3_ar;

  },
  methods: {
    onChange(event) {
      this.banner_description = event.html;
    },
    onChangeAR(event1) {
      this.banner_description_ar = event1.html;
    },
    onChangeSection1(event2) {
      this.section1_description = event2.html;
    },
    onChangeARSection1(event3) {
      this.section1_description_ar = event3.html;
    },
    onChangesection2_description(event4) {
      this.section2_description = event4.html;
    },
    onChangesection2_description_ar(event4) {
      this.section2_description_ar = event4.html;
    },
    onChangesection3_title1(event4) {
      this.section3_title1 = event4.html;
    },
    onChangesection3_title1_ar(event4) {
      this.section3_title1_ar = event4.html;
    },
    onChangesection3_description1(event4) {
      this.section3_description1 = event4.html;
    },
    onChangesection3_description1_ar(event4) {
      this.section3_description1_ar = event4.html;
    },
    onChangesection3_title2(event4) {
      this.section3_title2 = event4.html;
    },
    onChangesection3_title2_ar(event4) {
      this.section3_title2_ar = event4.html;
    },
    onChangesection3_description2(event4) {
      this.section3_description2 = event4.html;
    },
    onChangesection3_description2_ar(event4) {
      this.section3_description2_ar = event4.html;
    },
    onChangesection3_description3(event4) {
      this.section3_description3 = event4.html;
    },
    onChangesection3_description3_ar(event4) {
      this.section3_description3_ar = event4.html;
    },

    async fetchDetail() {
      try {
        await this.$store.dispatch("overView/fetchOverviewDetail", 1);
      } catch (err) {
        console.log(err);
      }
    },
    onSubmit(values) {
      console.log('{{{{{{{{{{____________________}}}}}}}}}}');
      console.log(values);
      console.log(this.banner_title_ar);
      console.log('{{{{{{{{{{this.banner_title_ar}}}}}}}}}}');
      const obj = {
        ...values,
        banner_description: this.banner_description,
        banner_description_ar: this.banner_description_ar,
        banner_image: this.banner_image,
        // banner_title: this.banner_title,
        // banner_title_ar: this.banner_title_ar,
        section1_image: this.section1_image,
        // section1_title: this.section1_title,
        // section1_title_ar: this.section1_title_ar,
        section1_description: this.section1_description,
        section1_description_ar: this.section1_description_ar,
        section2_image: this.section2_image,
        // section2_image_title1: this.section2_image_title1,
        // section2_image_title1_ar: this.section2_image_title1_ar,
        // section2_image_title2: this.section2_image_title2,
        // section2_image_title2_ar: this.section2_image_title2_ar,
        // section2_title: this.section2_title,
        // section2_title_ar: this.section2_title_ar,
        section2_description: this.section2_description,
        section2_description_ar: this.section2_description_ar,
        section3_image: this.section3_image,
        section3_title1: this.section3_title1,
        section3_title1_ar: this.section3_title1_ar,
        section3_description1: this.section3_description1,
        section3_description1_ar: this.section3_description1_ar,
        section3_title2: this.section3_title2,
        section3_title2_ar: this.section3_title2_ar,
        section3_description2: this.section3_description2,
        section3_description2_ar: this.section3_description2_ar,
        section3_description3: this.section3_description3,
        section3_description3_ar: this.section3_description3_ar,
      };
      let formData = new FormData();

      formData.append("banner_description", obj.banner_description);
      formData.append("banner_description_ar", obj.banner_description_ar);
      formData.append("banner_image", obj.banner_image);
      formData.append("banner_title", obj.banner_title);
      formData.append("banner_title_ar", obj.banner_title_ar);
      formData.append("section1_image", obj.section1_image);
      formData.append("section1_title", obj.section1_title);
      formData.append("section1_title_ar", obj.section1_title_ar);
      formData.append("section1_description", obj.section1_description);
      formData.append("section1_description_ar", obj.section1_description_ar);
      formData.append("section2_image", obj.section2_image);
      formData.append("section2_image_title1", obj.section2_image_title1);
      formData.append("section2_image_title1_ar", obj.section2_image_title1_ar);
      formData.append("section2_image_title2", obj.section2_image_title2);
      formData.append("section2_image_title2_ar", obj.section2_image_title2_ar);
      formData.append("section2_title", obj.section2_title);
      formData.append("section2_title_ar", obj.section2_title_ar);
      formData.append("section2_description", obj.section2_description);
      formData.append("section2_description_ar", obj.section2_description_ar);
      formData.append("section3_image", obj.section3_image);
      formData.append("section3_title1", obj.section3_title1);
      formData.append("section3_title1_ar", obj.section3_title1_ar);
      formData.append("section3_description1", obj.section3_description1);
      formData.append("section3_description1_ar", obj.section3_description1_ar);
      formData.append("section3_title2", obj.section3_title2);
      formData.append("section3_title2_ar", obj.section3_title2_ar);
      formData.append("section3_description2", obj.section3_description2);
      formData.append("section3_description2_ar", obj.section3_description2_ar);
      formData.append("section3_description3", obj.section3_description3);
      formData.append("section3_description3_ar", obj.section3_description3_ar);
      this.$store.dispatch("overView/put", formData);
      this.$swal({
        position: "center",
        icon: "success",
        title: "Overview Page has been Updated",
        showConfirmButton: false,
        timer: 2000,
      });
      this.fetchDetail();
      this.$router.push("/cms");
    },
    handleCancel() {
      this.$router.push("/cms");
    },
    onFileChange(event, title) {
      const file = event.target.files[0];
      if (title == "banner_image") {
        this.banner_image = event.target.files[0];
      } else if (title == "section1_image") {
        this.section1_image = event.target.files[0];
      }
      else if (title == "section2_image") {
        this.section2_image = event.target.files[0];
      }
      else if (title == "section3_image") {
        this.section3_image = event.target.files[0];
      }

      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          if (title == "banner_image") {
            this.banner_imageData = e.target.result;
          } else if (title == "section1_image") {
            this.section1_imageData = e.target.result;
          }
          else if (title == "section2_image") {
            this.section2_imageData = e.target.result;
          }
          else if (title == "section3_image") {
            this.section3_imageData = e.target.result;
          }
        };
        reader.readAsDataURL(file);
      } else {
        alert("Please select a valid image file.");
      }
    },
  },
};
</script>
