<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0 d-flex justify-content-between align-content-center">
      <h6>News Releases</h6>
      <RouterLink to="/news-releases/add" class="btn btn-success">Create</RouterLink>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <!-- Data Table -->
      <data-table :header-list="headerList" :data-list="paginatedNewsReleases">
        <template v-slot:action="slotProps">
          <router-link
            :to="{ path: `/news-releases/edit/${slotProps.row.id}` }"
            class="btn btn-outline-dark px-2 py-1"
          >
            <i class="fa fa-pen"></i>
          </router-link>
          <button
            class="btn btn-outline-danger px-2 py-1 mx-1"
            @click="onDelete(slotProps.row.id)"
          >
            <i class="fa fa-trash"></i>
          </button>
        </template>
      </data-table>

      <!-- Pagination -->
      <div class="d-flex justify-content-center mt-3 gap-3 mr-3">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-text="First"
          prev-text="Prev"
          next-text="Next"
          last-text="Last"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from "../../components/DataTable.vue";
import { RouterLink } from "vue-router";
import { mapGetters } from "vuex";

const headerList = [
  { name: "Sr.no", id: "id" },
  { name: "Title", id: "title" },
  { name: "Action", id: "action" },
];

export default {
  name: "NewsReleases",
  components: {
    DataTable,
    RouterLink,
  },
  data() {
    return {
      headerList: headerList,
      currentPage: 1, // Current page
      perPage: 10, // Rows per page
    };
  },
  computed: {
    ...mapGetters("newReleases", ["newsReleases", "isLoading"]),

    // Calculate total rows from the news releases list
    totalRows() {
      return this.newsReleases.length;
    },

    // Get paginated news releases for the current page
    paginatedNewsReleases() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.newsReleases.slice(start, end);
    },
  },
  created() {
    this.$store.dispatch("newReleases/fetchNewReleases", null, {
      root: true,
    });
  },
  methods: {
    onDelete(id) {
      this.$swal({
        title: `Are you sure you want to delete News Release?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("newReleases/delete", id);
          this.$swal("Deleted!", `News Release has been deleted.`, "success");
        }
      });
    },
  },
};
</script>

<style scoped>
/* Add custom styles for pagination or the component if needed */
</style>



<!-- <template>
  <div class="card my-4 mx-4">
    <div
      class="card-header pb-0 d-flex justify-content-between align-content-center"
    >
      <h6>News Releases</h6>
      <RouterLink to="/news-releases/add" class="btn btn-success"
        >Create</RouterLink
      >
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <data-table :header-list="headerList" :data-list="newsReleases">
        <template v-slot:action="slotProps">
          <router-link
            :to="{ path: `/news-releases/edit/${slotProps.row.id}` }"
            class="btn btn-outline-dark px-2 py-1"
          >
            <i className="fa fa-pen"></i>
          </router-link>
          <button
            class="btn btn-outline-danger px-2 py-1 mx-1"
            @click="onDelete(slotProps.row.id)"
          >
            <i className="fa fa-trash"></i>
          </button>
        </template>
      </data-table>
    </div>
  </div>
</template>
<script>
import DataTable from "../../components/DataTable.vue";
import { RouterLink } from "vue-router";
import { mapGetters } from "vuex";
const headerList = [
  { name: "Sr.no", id: "id" },
  { name: "Title", id: "title" },
  { name: "Action", id: "action" },
];

export default {
  name: "News Release",
  components: {
    DataTable,
    RouterLink,
  },
  data() {
    return {
      headerList: headerList,
    };
  },
  created() {
    this.$store.dispatch("newReleases/fetchNewReleases", null, {
      root: true,
    });
  },
  computed: {
    ...mapGetters("newReleases", ["newsReleases", "isLoading"]),
  },
  methods: {
    onDelete(id) {
      this.$swal({
        title: `Are you sure you want to delete News Release ?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("newReleases/delete", id);
          this.$swal("Deleted!", `News Release has been deleted.`, "success");
        }
      });
    },
  },
};
</script> -->
