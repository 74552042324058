import API from "../../api/api";
import router from "../../router/index";
const state = () => ({
  user: [],
  isAuth: false,
  error: true,
  isError: null,
  loading: false,
});

const mutations = {
  registerUser(state, paylaod) {
    state.user = paylaod;
    state.isAuth = true;
  },
  loginUser(state, payload) {
    state.user = payload?.token?.user;
    state.token = payload?.token.authToken;

    state.isAuth = true;
  },
  serverMessage(state, payload) {
    state.isAuth = false;
    (state.isError = true), (state.error = payload);
  },
  isLoading(state, payload) {
    state.loading = payload;
  },
};

const actions = {
  async register({ commit }, payload) {
    await API.post("register", payload)
      .then(res => {
        commit("registerUser", res.data.data);
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  async login({ commit }, payload) {
    await API.post("login", payload)
      .then(res => {
        if (res.data.code == 200) {
          commit("loginUser", res.data.data);
          const data = res.data.data;
          localStorage.setItem("email", data?.token?.user?.email);
          localStorage.setItem("token", data?.token?.authToken);
          router.push("/dashboard");
        } else {
          commit("serverMessage", res.data.message);
          router.push("/sign-in");
        }
      })
      .catch(err => {
        console.log("ERROR", err);
      });
  },
  updateInput({ commit }, e) {
    commit("setDetailInput", e);
  },
};

const getters = {
  user: state => state.user,
  isAuth: state => state.isAuth,
  error: state => state.error,
  isError: state => state.isError,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
