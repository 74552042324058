<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Model Create</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3" v-if="this.model">
        <Form
          as="form"
          @submit="onFormSubmit"
          class="form"
          v-slot="{ errors }"
          enctype="multipart/form-data"
        >
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Model Name</label>
              <Field
                v-model="this.model.name"
                name="name"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.name }"
              />
              <div class="invalid-feedback">{{ errors.name }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Model Name</label>
              <Field
                v-model="this.model.name_ar"
                name="name_ar"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.name_ar }"
              />
              <div class="invalid-feedback">{{ errors.name_ar }}</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Model Description</label>
              <Field
                v-model="this.model.description"
                name="description"
                type="text"
                as="textarea"
                class="form-control"
                :class="{ 'is-invalid': errors.description }"
              />
              <div class="invalid-feedback">{{ errors.description }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Model Description</label>
              <Field
                v-model="this.model.description_ar"
                name="description_ar"
                type="text"
                as="textarea"
                class="form-control"
                :class="{ 'is-invalid': errors.description_ar }"
              />
              <div class="invalid-feedback">{{ errors.description_ar }}</div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model Power</label>
              <Field
                v-model="this.model.power"
                name="power"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.power }"
              />
              <div class="invalid-feedback">{{ errors.power }}</div>
            </div>
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model Torque</label>
              <Field
                v-model="this.model.torque"
                name="torque"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.torque }"
                :value="formData.torque"
                @input="formData.torque = $event.target.value"
              />
              <div class="invalid-feedback">{{ errors.torque }}</div>
            </div>
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model Wheelbase</label>
              <Field
                v-model="this.model.wheelbase"
                name="wheelbase"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.wheelbase }"
              />
              <div class="invalid-feedback">{{ errors.wheelbase }}</div>
            </div>
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model VR Link</label>
              <Field
                v-model="this.model.vr_link"
                name="vr_link"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.vr_link }"
              />
              <div class="invalid-feedback">{{ errors.vr_link }}</div>
            </div>
          </div>

          <label for="exampleInputEmail1">Model Category</label>
          <Field
            name="modelCategory"
            as="select"
            class="form-control"
            v-model="this.model.categoryId"
            :class="{ 'is-invalid': errors.modelCategory }"
          >
            <option value="" disabled>Select value</option>
            <option v-for="(category, index) in modelsCategories" :key="index" :value="category.id">
              {{ category.name }}
            </option>
          </Field>
          <div class="invalid-feedback">{{ errors.modelCategory }}</div>

          <div class="form-group">
            <div class="form-group">
              <label for="image1">Image 1</label>
              <input
                type="file"
                id="image1"
                class="form-control"
                @change="onFileChange($event, 'global', 'image1')"
              />
              <!-- Show image from the URL if `model.image1` exists and no preview -->
              <div v-if="this.model.image1 && !this.model.image1_preview">
                <img
                  :src="imageApi + this.model.image1"
                  alt="Image 1"
                  class="img-thumbnail"
                  height="200"
                  width="230"
                />
              </div>

              <!-- Show image preview if `model.image1_preview` exists -->
              <div v-if="this.model.image1_preview">
                <img
                  :src="this.model.image1_preview"
                  alt="Image 1 Preview"
                  class="img-thumbnail"
                  height="200"
                  width="230"
                />
              </div>
            </div>

            <div class="form-group">
              <label for="image1_title">Image 1 Title (EN)</label>
              <Field
                id="image1_title"
                name="image1_title"
                v-model="this.model.image1_title"
                class="form-control"
              />
            </div>

            <div class="form-group">
              <label for="image1_title_ar">Image 1 Title (AR)</label>
              <Field
                id="image1_title_ar"
                name="image1_title_ar"
                v-model="this.model.image1_title_ar"
                class="form-control"
              />
            </div>

            <div class="form-group">
              <label for="image1_description">Image 1 Description (EN)</label>
              <Field
                id="image1_description"
                name="image1_description"
                v-model="this.model.image1_description"
                class="form-control"
              />
            </div>

            <div class="form-group">
              <label for="image1_description_ar">Image 1 Description (AR)</label>
              <Field
                id="image1_description_ar"
                name="image1_description_ar"
                v-model="this.model.image1_description_ar"
                class="form-control"
              />
            </div>
          </div>

          <!-- Image 2 -->
          <!-- <div class="form-group">
            <label for="image2">Image 2</label>
            <input
              type="file"
              id="image2"
              class="form-control"
              @change="onFileChange($event, 'global', 'image2')"
            />
            <div v-if="formData.image2_preview">
              <img
                :src="formData.image2_preview"
                alt="Image 2 Preview"
                class="img-thumbnail"
                height="200"
                width="230"
              />
            </div>
            <div class="form-group">
              <label for="image2_title">Image 2 Title (EN)</label>
              <Field
                id="image2_title"
                name="image2_title"
                v-model="formData.image2_title"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="image2_title_ar">Image 2 Title (AR)</label>
              <Field
                id="image2_title_ar"
                name="image2_title_ar"
                v-model="formData.image2_title_ar"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="image2_description">Image 2 Description (EN)</label>
              <Field
                id="image2_description"
                name="image2_description"
                v-model="formData.image2_description"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="image2_description_ar"
                >Image 2 Description (AR)</label
              >
              <Field
                id="image2_description_ar"
                name="image2_description_ar"
                v-model="formData.image2_description_ar"
                class="form-control"
              />
            </div>
          </div> -->

          <div class="form-group">
            <div class="form-group">
              <label for="image2">Image 2</label>
              <input
                type="file"
                id="image2"
                class="form-control"
                @change="onFileChange($event, 'global', 'image2')"
              />

              <!-- Show image from the URL if `model.image2` exists and no preview -->
              <div v-if="this.model.image2 && !this.model.image2_preview">
                <img
                  :src="imageApi + this.model.image2"
                  alt="Image 2"
                  class="img-thumbnail"
                  height="200"
                  width="230"
                />
              </div>

              <!-- Show image preview if `model.image2_preview` exists -->
              <div v-if="this.model.image2_preview">
                <img
                  :src="this.model.image2_preview"
                  alt="Image 2 Preview"
                  class="img-thumbnail"
                  height="200"
                  width="230"
                />
              </div>
            </div>

            <!-- Image 2 Title (EN) -->
            <div class="form-group">
              <label for="image2_title">Image 2 Title (EN)</label>
              <Field
                id="image2_title"
                name="image2_title"
                v-model="this.model.image2_title"
                class="form-control"
              />
            </div>

            <!-- Image 2 Title (AR) -->
            <div class="form-group">
              <label for="image2_title_ar">Image 2 Title (AR)</label>
              <Field
                id="image2_title_ar"
                name="image2_title_ar"
                v-model="this.model.image2_title_ar"
                class="form-control"
              />
            </div>

            <!-- Image 2 Description (EN) -->
            <div class="form-group">
              <label for="image2_description">Image 2 Description (EN)</label>
              <Field
                id="image2_description"
                name="image2_description"
                v-model="this.model.image2_description"
                class="form-control"
              />
            </div>

            <!-- Image 2 Description (AR) -->
            <div class="form-group">
              <label for="image2_description_ar">Image 2 Description (AR)</label>
              <Field
                id="image2_description_ar"
                name="image2_description_ar"
                v-model="this.model.image2_description_ar"
                class="form-control"
              />
            </div>
          </div>

          <div v-if="this.model.designType == 'normal'">
            <div
              v-for="(section, index) in this.model.normalSections"
              :key="`normal-section-${index}`"
            >
              <div class="form-group row">
                <!-- Section Title EN -->
                <div class="col-md-6">
                  <label :for="`normalSections[${index}].title_en`"> Section Title (EN) </label>
                  <Field
                    :name="`normalSections[${index}].title_en`"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': errors.normalSections && errors.normalSections[index]?.title_en,
                    }"
                    v-model="section.title_en"
                  />
                  <div v-if="errors.normalSections?.[index]?.title_en" class="invalid-feedback">
                    {{ errors.normalSections[index].title_en }}
                  </div>
                </div>

                <!-- Section Title AR -->
                <div class="col-md-6">
                  <label :for="`normalSections[${index}].title_ar`"> Section Title (AR) </label>
                  <Field
                    :name="`normalSections[${index}].title_ar`"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid': errors.normalSections && errors.normalSections[index]?.title_ar,
                    }"
                    v-model="section.title_ar"
                  />
                  <div v-if="errors.normalSections?.[index]?.title_ar" class="invalid-feedback">
                    {{ errors.normalSections[index].title_ar }}
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <!-- Section Description EN -->
                <div class="col-md-6">
                  <label :for="`normalSections[${index}].description_en`">
                    Section Description (EN)
                  </label>
                  <Field
                    :name="`normalSections[${index}].description_en`"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        errors.normalSections && errors.normalSections[index]?.description_en,
                    }"
                    v-model="section.description_en"
                  />
                  <div
                    v-if="errors.normalSections?.[index]?.description_en"
                    class="invalid-feedback"
                  >
                    {{ errors.normalSections[index].description_en }}
                  </div>
                </div>

                <!-- Section Description AR -->
                <div class="col-md-6">
                  <label :for="`normalSections[${index}].description_ar`">
                    Section Description (AR)
                  </label>
                  <Field
                    :name="`normalSections[${index}].description_ar`"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        errors.normalSections && errors.normalSections[index]?.description_ar,
                    }"
                    v-model="section.description_ar"
                  />
                  <div
                    v-if="errors.normalSections?.[index]?.description_ar"
                    class="invalid-feedback"
                  >
                    {{ errors.normalSections[index].description_ar }}
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <!-- Section Image EN -->
                <div class="col-md-6">
                  <label :for="`normalSections[${index}].image_en`"> Section Image (EN) </label>
                  <input
                    type="file"
                    class="form-control"
                    @change="(event) => onFileChange(event, 'normalSections', 'image_en', index)"
                  />

                  <!-- Show image from model if it exists -->
                  <div v-if="section.image_en && !section.image_en_preview">
                    <img
                      :src="imageApi + section.image_en"
                      alt="Image EN"
                      class="img-thumbnail"
                      height="200"
                      width="230"
                    />
                  </div>

                  <!-- Show image preview if `image_en_preview` exists -->
                  <div v-if="section.image_en_preview">
                    <img
                      :src="section.image_en_preview"
                      alt="Image EN Preview"
                      class="img-thumbnail"
                      height="200"
                      width="230"
                    />
                  </div>
                </div>

                <!-- Section Image AR -->
                <div class="col-md-6">
                  <label :for="`normalSections[${index}].image_ar`"> Section Image (AR) </label>
                  <input
                    type="file"
                    class="form-control"
                    @change="(event) => onFileChange(event, 'normalSections', 'image_ar', index)"
                  />

                  <!-- Show image from model if it exists -->
                  <div v-if="section.image_ar && !section.image_ar_preview">
                    <img
                      :src="imageApi + section.image_ar"
                      alt="Image AR"
                      class="img-thumbnail"
                      height="200"
                      width="230"
                    />
                  </div>

                  <!-- Show image preview if `image_ar_preview` exists -->
                  <div v-if="section.image_ar_preview">
                    <img
                      :src="section.image_ar_preview"
                      alt="Image AR Preview"
                      class="img-thumbnail"
                      height="200"
                      width="230"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Add More Item -->
            <button
              type="button"
              @click="addSection('normalSections')"
              class="btn btn-outline-primary"
            >
              Add More Item
            </button>
          </div>
          <div v-if="this.model.designType == 'advanced'">
            <div v-for="(section, index) in this.model.advancedSections" :key="index">
              <div class="form-group">
                <h5>{{ `Section ${index + 1}` }}</h5>

                <div v-for="(subsection, subsectionIndex) in section" :key="subsectionIndex">
                  <div
                    v-for="(subsectionInner, subsectionIndexInner) in subsection"
                    :key="subsectionIndexInner"
                  >
                    <div class="form-group row">
                      <!-- Title (EN) -->
                      <div class="col-md-6">
                        <label
                          :for="`advancedSections[${index}][${subsectionIndex}][${subsectionIndexInner}].title_en`"
                        >
                          {{ subsectionInner.title_en || 'Title (EN)' }}
                        </label>
                        <Field
                          :name="`advancedSections[${index}][${subsectionIndex}][${subsectionIndexInner}].title_en`"
                          type="text"
                          class="form-control"
                          v-model="subsectionInner.title_en"
                        />
                      </div>

                      <!-- Title (AR) -->
                      <div class="col-md-6">
                        <label
                          :for="`advancedSections[${index}][${subsectionIndex}][${subsectionIndexInner}].title_ar`"
                        >
                          Title (AR)
                        </label>
                        <Field
                          :name="`advancedSections[${index}][${subsectionIndex}][${subsectionIndexInner}].title_ar`"
                          type="text"
                          class="form-control"
                          v-model="subsectionInner.title_ar"
                          :class="{
                            'is-invalid':
                              errors.advancedSections &&
                              errors.advancedSections[index] &&
                              errors.advancedSections[index][subsectionIndex] &&
                              errors.advancedSections[index][subsectionIndex][
                                subsectionIndexInner
                              ] &&
                              errors.advancedSections[index][subsectionIndex][subsectionIndexInner]
                                .title_ar,
                          }"
                        />
                        <div
                          v-if="
                            errors.advancedSections &&
                            errors.advancedSections[index] &&
                            errors.advancedSections[index][subsectionIndex] &&
                            errors.advancedSections[index][subsectionIndex][subsectionIndexInner] &&
                            errors.advancedSections[index][subsectionIndex][subsectionIndexInner]
                              .title_ar
                          "
                          class="invalid-feedback"
                        >
                          {{
                            errors.advancedSections[index][subsectionIndex][subsectionIndexInner]
                              .title_ar
                          }}
                        </div>
                      </div>
                    </div>

                    <div class="form-group row">
                      <!-- Description (EN) -->
                      <div class="col-md-6">
                        <label
                          :for="`advancedSections[${index}][${subsectionIndex}][${subsectionIndexInner}].description_en`"
                        >
                          Description (EN)
                        </label>
                        <Field
                          :name="`advancedSections[${index}][${subsectionIndex}][${subsectionIndexInner}].description_en`"
                          type="text"
                          class="form-control"
                          v-model="subsectionInner.description_en"
                          :class="{
                            'is-invalid':
                              errors.advancedSections &&
                              errors.advancedSections[index] &&
                              errors.advancedSections[index][subsectionIndex] &&
                              errors.advancedSections[index][subsectionIndex][
                                subsectionIndexInner
                              ] &&
                              errors.advancedSections[index][subsectionIndex][subsectionIndexInner]
                                .description_en,
                          }"
                        />
                        <div
                          v-if="
                            errors.advancedSections &&
                            errors.advancedSections[index] &&
                            errors.advancedSections[index][subsectionIndex] &&
                            errors.advancedSections[index][subsectionIndex][subsectionIndexInner] &&
                            errors.advancedSections[index][subsectionIndex][subsectionIndexInner]
                              .description_en
                          "
                          class="invalid-feedback"
                        >
                          {{
                            errors.advancedSections[index][subsectionIndex][subsectionIndexInner]
                              .description_en
                          }}
                        </div>
                      </div>

                      <!-- Description (AR) -->
                      <div class="col-md-6">
                        <label
                          :for="`advancedSections[${index}][${subsectionIndex}][${subsectionIndexInner}].description_ar`"
                        >
                          Description (AR)
                        </label>
                        <Field
                          :name="`advancedSections[${index}][${subsectionIndex}][${subsectionIndexInner}].description_ar`"
                          type="text"
                          class="form-control"
                          v-model="subsectionInner.description_ar"
                          :class="{
                            'is-invalid':
                              errors.advancedSections &&
                              errors.advancedSections[index] &&
                              errors.advancedSections[index][subsectionIndex] &&
                              errors.advancedSections[index][subsectionIndex][
                                subsectionIndexInner
                              ] &&
                              errors.advancedSections[index][subsectionIndex][subsectionIndexInner]
                                .description_ar,
                          }"
                        />
                        <div
                          v-if="
                            errors.advancedSections &&
                            errors.advancedSections[index] &&
                            errors.advancedSections[index][subsectionIndex] &&
                            errors.advancedSections[index][subsectionIndex][subsectionIndexInner] &&
                            errors.advancedSections[index][subsectionIndex][subsectionIndexInner]
                              .description_ar
                          "
                          class="invalid-feedback"
                        >
                          {{
                            errors.advancedSections[index][subsectionIndex][subsectionIndexInner]
                              .description_ar
                          }}
                        </div>
                      </div>
                    </div>

                    <!-- Image Upload Section -->
                    <div class="form-group row">
                      <!-- Section Image EN -->
                      <div class="col-md-6">
                        <label
                          :for="`advancedSections[${index}][${subsectionIndex}][${subsectionIndexInner}].image_en`"
                        >
                          Section Image (EN)
                        </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="
                            (event) =>
                              onFileChange(
                                event,
                                'advancedSections',
                                'image_en',
                                subsectionIndex,
                                index,
                                subsectionIndexInner,
                              )
                          "
                        />

                        <!-- Show image from model if it exists and no preview -->
                        <div v-if="subsectionInner.image_en && !subsectionInner.image_en_preview">
                          <img
                            :src="imageApi + subsectionInner.image_en"
                            alt="Image EN"
                            class="img-thumbnail"
                            height="200"
                            width="230"
                          />
                        </div>

                        <!-- Show image preview if `image_en_preview` exists -->
                        <div v-if="subsectionInner.image_en_preview">
                          <img
                            :src="subsectionInner.image_en_preview"
                            alt="Image EN Preview"
                            class="img-thumbnail"
                            height="200"
                            width="230"
                          />
                        </div>
                      </div>

                      <!-- Section Image AR -->
                      <div class="col-md-6">
                        <label
                          :for="`advancedSections[${index}][${subsectionIndex}][${subsectionIndexInner}].image_ar`"
                        >
                          Section Image (AR)
                        </label>
                        <input
                          type="file"
                          class="form-control"
                          @change="
                            (event) =>
                              onFileChange(
                                event,
                                'advancedSections',
                                'image_ar',
                                subsectionIndex,
                                index,
                                subsectionIndexInner,
                              )
                          "
                        />

                        <!-- Show image from model if it exists and no preview -->
                        <div v-if="subsectionInner.image_ar && !subsectionInner.image_ar_preview">
                          <img
                            :src="imageApi + subsectionInner.image_ar"
                            alt="Image AR"
                            class="img-thumbnail"
                            height="200"
                            width="230"
                          />
                        </div>

                        <!-- Show image preview if `image_ar_preview` exists -->
                        <div v-if="subsectionInner.image_ar_preview">
                          <img
                            :src="subsectionInner.image_ar_preview"
                            alt="Image AR Preview"
                            class="img-thumbnail"
                            height="200"
                            width="230"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    type="button"
                    @click="addSection('advancedSections', index, subsectionIndex)"
                    class="btn btn-outline-primary"
                  >
                    Add More Item
                  </button>
                </div>
              </div>
            </div>
          </div>

          <button type="submit" class="btn btn-danger my-2">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success my-2 cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, useForm } from 'vee-validate';
import * as yup from 'yup';
import imageApi from '../../api/imageapi';

import { mapGetters } from 'vuex';
export default {
  name: 'Model Add',
  components: {
    Form,
    Field,
  },
  setup() {
    const { setFieldValue } = useForm();
    return { setFieldValue };
  },
  data() {
    return {
      imageApi: imageApi,
      formData: {
        image1: null,
        image1_preview: null,
        image1_title: '',
        image1_title_ar: '',
        image1_description: '',
        image1_description_ar: '',
        image2: null,
        image2_preview: null,
        image2_title: '',
        image2_title_ar: '',
        image2_description: '',
        image2_description_ar: '',

        name: '',
        name_ar: '',
        description: '',
        description_ar: '',
        categoryId: 1,
        designType: 'normal', // Default to 'normal'
        normalSections: [
          {
            title_en: '',
            title_ar: '',
            description_en: '',
            description_ar: '',
            image_en: null,
            image_ar: null,
          },
        ],
        advancedSections: {
          section1: [
            {
              title_en: '',
              title_ar: '',
              description_en: '',
              description_ar: '',
              image_en: null,
              image_ar: null,
            },
          ],
          section2: [
            {
              title_en: '',
              title_ar: '',
              description_en: '',
              description_ar: '',
              image_en: null,
              image_ar: null,
            },
          ],
          section3: [
            {
              title_en: '',
              title_ar: '',
              description_en: '',
              description_ar: '',
              image_en: null,
              image_ar: null,
            },
          ],
          section4: [
            {
              title_en: '',
              title_ar: '',
              description_en: '',
              description_ar: '',
              image_en: null,
              image_ar: null,
            },
          ],
        },
      },
      errors: {},
      touched: {},
      schema: yup.object().shape({}),
    };
  },
  computed: {
    ...mapGetters('modelsCategory', ['modelsCategories']),
    ...mapGetters('models', ['model', 'isLoading']),
    id() {
      return this.$route.params.id;
    },
  },
  mounted() {
    // Call fetchDetail as soon as the component is mounted
    if (this.id) {
      this.$store.dispatch('modelsCategory/fetchModelsCategories', null, {
        root: true,
      });
      this.fetchDetail();
    }
  },

  methods: {
    onFileChange(
      event,
      sectionType,
      fieldName,
      index,
      sectionName = null,
      subsectionIndexInner = null,
    ) {
      console.log(
        sectionName,
        subsectionIndexInner,
        'sectionName, subsectionIndexInner',
        this.model,
      );
      const file = event.target.files[0];

      // Ensure the selected file is an image
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          // If the section is 'normalSections', store the file for the specific section
          if (sectionType === 'normalSections') {
            this.model.normalSections[index][fieldName] = file; // Store the file itself
            this.model.normalSections[index][`${fieldName}_preview`] = e.target.result; // Store base64 preview
            console.log(this.formData, 'normalSections updated');
          }
          // If the section is 'advancedSections', store the file for the specific section and field
          else if (sectionType === 'advancedSections') {
            if (subsectionIndexInner !== null) {
              // If subsectionIndexInner is provided, handle nested structure
              this.model.advancedSections[sectionName][index][subsectionIndexInner][
                fieldName
              ] = file; // Store the file itself
              this.model.advancedSections[sectionName][index][subsectionIndexInner][
                `${fieldName}_preview`
              ] = e.target.result; // Store base64 preview
            } else {
              // Fallback in case subsectionIndexInner is not provided (if needed)
              this.model.advancedSections[sectionName][index][fieldName] = file; // Store the file itself
              this.model.advancedSections[sectionName][index][`${fieldName}_preview`] =
                e.target.result; // Store base64 preview
            }
            console.log(this.formData, 'advancedSections updated');
          }
          // Handle standalone image1 or image2
          else if (sectionType === 'global' && (fieldName === 'image1' || fieldName === 'image2')) {
            this.model[fieldName] = file; // Store the file itself
            this.model[`${fieldName}_preview`] = e.target.result; // Store base64 preview
            console.log(this.formData, `${fieldName} updated`);
          }
        };
        reader.readAsDataURL(file);
        console.log(this.model.advancedSections, 'advancedSections updated');
        // This loads the image as a base64 string (for preview)
      } else {
        alert('Please select a valid image file.');
      }
    },
    addSection(sectionType, index, subsectionIndex) {
      console.log(index, subsectionIndex, 'fffff');
      // this.model.advancedSections[0]["section1"].push({
      //   tit:"dfdf"
      // })
      // this.model.advancedSections[0]["section1"].push({
      //     title_en: "",
      //     title_ar: "",
      //     description_en: "",
      //     description_ar: "",
      //     image_en: null,
      //     image_ar: null,
      //     image_en_preview: null, // Field for preview image in base64
      //     image_ar_preview: null, // Field for preview image in base64
      //   });
      // console.log("add section", this.model.advancedSections[0]["section1"]);
      if (sectionType === 'normalSections') {
        // Add a new section to normalSections with fields for image preview
        this.model.normalSections.push({
          title_en: '',
          title_ar: '',
          description_en: '',
          description_ar: '',
          image_en: null,
          image_ar: null,
          image_en_preview: null, // Field for preview image in base64
          image_ar_preview: null, // Field for preview image in base64
        });
      } else {
        // } else if (this.model.advancedSections[0]["section1"]) {
        // Add a new section to the specified advancedSections with fields for image preview
        this.model.advancedSections[index][subsectionIndex].push({
          title_en: '',
          title_ar: '',
          description_en: '',
          description_ar: '',
          image_en: null,
          image_ar: null,
          image_en_preview: null, // Field for preview image in base64
          image_ar_preview: null, // Field for preview image in base64
        });
        console.log('add sectionffff', this.model.advancedSections[0]['section1']);
      }
    },

    fetchDetail() {
      try {
        console.log(this.id, 'Fetching details for this ID');

        // Dispatch the Vuex action to fetch the model details
        this.$store
          .dispatch('models/fetchModelDetail', this.id)
          .then(() => {
            // Access the fetched model from the Vuex state
            const modelData = this.model; // Using mapGetters to access `model`

            if (!modelData) {
              console.error('No model data found in Vuex state');
              return;
            }

            console.log('Fetched Model Data:', modelData);
            if (modelData) {
              console.log('Fetched Model Data:', modelData);

              // Deep assignment for formData to ensure reactivity
              Object.assign(this.formData, modelData);
              console.log(this.formData, 'Assigned to formData');
            }
            console.log(this.formData, 'assssigned');

            console.log('Form data populated successfully:', this.formData);
          })
          .catch((error) => {
            console.error('Error during fetchModelDetail action:', error);
          });
      } catch (err) {
        console.error('Error in fetchDetail method:', err);
      }
    },
    // When form is submitted, handle image files and append them to FormData
    onFormSubmit(values) {
      console.log(values, 'Form Submission Values');

      const formData = new FormData();

      // Append model fields (e.g., name, description, category)
      const modelFields = {
        id: this.model.id,
        name: this.model.name,
        name_ar: this.model.name_ar,
        description: this.model.description,
        description_ar: this.model.description_ar,
        categoryId: this.model.categoryId,
        power: this.model.power,
        torque: this.model.torque,
        wheelbase: this.model.wheelbase,
        vr_link: this.model.vr_link,
        designType: this.model.designType, // Include designType
      };

      for (const [key, value] of Object.entries(modelFields)) {
        formData.append(key, value);
      }

      // Handle image1 and its metadata
      if (this.model.image1) {
        formData.append('image1', this.model.image1);
        formData.append('image1_title', this.model.image1_title || '');
        formData.append('image1_title_ar', this.model.image1_title_ar || '');
        formData.append('image1_description', this.model.image1_description || '');
        formData.append('image1_description_ar', this.model.image1_description_ar || '');
      }

      // Handle image2 and its metadata
      if (this.model.image2) {
        formData.append('image2', this.model.image2);
        formData.append('image2_title', this.model.image2_title || '');
        formData.append('image2_title_ar', this.model.image2_title_ar || '');
        formData.append('image2_description', this.model.image2_description || '');
        formData.append('image2_description_ar', this.model.image2_description_ar || '');
      }

      // Handle normalSections
      if (this.model.designType === 'normal') {
        this.model.normalSections.forEach((section, index) => {
          [
            'title_en',
            'title_ar',
            'description_en',
            'description_ar',
            'image_en',
            'image_ar',
          ].forEach((key) => {
            formData.append(`normalSections[${index}][${key}]`, section[key] || '');
          });
        });
      }

      if (this.model.designType === 'advanced') {
        console.log(this.model.advancedSections, 'Advanced Sections Data');
        this.model.advancedSections.forEach((sectionObj) => {
          // Get the key of the current section (e.g., "section1", "section2", etc.)
          const sectionName = Object.keys(sectionObj)[0];
          const sections = sectionObj[sectionName]; // This will be the array of section objects

          // Iterate over each section in the `sections` array
          sections.forEach((section, index) => {
            // Loop over the keys to append each field to formData
            [
              'title_en',
              'title_ar',
              'description_en',
              'description_ar',
              'image_en',
              'image_ar',
            ].forEach((key) => {
              // Append each key-value pair to formData (empty string if the value is missing)
              formData.append(
                `advancedSections[${sectionName}][${index}][${key}]`,
                section[key] || '', // Append value or empty string if it's undefined or null
              );
            });
          });
        });
      }

      // Submit form data (can send to backend API or other service)
      this.$store
        .dispatch('models/put', { id: this.model.id, data: formData })
        .then((res) => {
          if (res == 200) {
            this.$swal({
              position: 'center',
              icon: 'success',
              title: 'Model has been Updated',
              showConfirmButton: false,
              timer: 2000,
            });
            // actions.resetForm();
            this.$router.push('/models');
          }
        })
        .catch((error) => {
          // Handle any errors
          console.error('Error updating model:', error);
          this.$swal('Error!', 'There was an issue updating the model.', 'error');
        });
    },
  },
};
</script>
