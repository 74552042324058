import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

export function exportToCSV(dataList, headerList) {
  const headers = headerList.map((header) => header.name);
  const rows = dataList.map((item) => {
    return headerList.map((header) => {
      let value = item[header.id] || '';
      value = String(value);
      // If value contains commas, newlines, or quotes, escape them
      if (value.includes(',') || value.includes('\n') || value.includes('"')) {
        return `"${value.replace(/"/g, '""')}"`;
      }
      return value;
    });
  });

  const csvContent = [headers.join(','), ...rows.map((row) => row.join(','))].join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', 'data.csv');
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function exportToPDF(dataList, headerList) {
  const doc = new jsPDF();
  const tableColumn = headerList.map((header) => header.name); // Extract header names
  const tableRows = dataList.map((item) => {
    return headerList.map((header) => item[header.id] || ''); // Extract data for each row
  });

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 20,
    margin: { top: 10 },
  });

  doc.save('data.pdf');
}
