<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <!-- Header row with title, items per page, search input, and export buttons -->
      <div class="d-flex justify-content-between align-items-center">
        <!-- Models Title -->
        <h6 class="mb-0">Media Contact Inquiries</h6>

        <!-- Items per page select -->
        <div class="d-flex align-items-center">
          <label for="itemsPerPage" class="mr-2 mb-0">Items per page</label>
          <div class="form-group mb-0 w-auto">
            <select id="itemsPerPage" class="form-control" v-model="perPage" @change="onItemsPerPageChange">
              <option :value="10">10</option>
              <option :value="20">20</option>
              <option :value="50">50</option>
              <option :value="Number.MAX_VALUE">All</option>
            </select>
          </div>
        </div>

        <!-- Search input -->
        <div class="form-group mb-0 w-25">
          <label for="search" class="d-none">Search</label>
          <input type="text" id="search" v-model="searchQuery" class="form-control"
            placeholder="Search by name, email..." @input="filterSubscribes" />
        </div>

        <!-- Export Buttons (align next to each other) -->
        <div class="d-flex gap-2 ml-2">
          <button class="btn btn-primary" @click="handleExportCSV">Export to CSV</button>
          <button class="btn btn-success" @click="handleExportPDF">Export to PDF</button>
        </div>
      </div>
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <!-- Data Table -->
      <data-table :data-list="paginatedMediaContacts" :headerList="headerList" />

      <!-- Pagination -->
      <div class="d-flex justify-content-center mt-3 gap-3 mr-3">
        <b-pagination v-if="perPage !== Number.MAX_VALUE" v-model="currentPage" :total-rows="totalRows"
          :per-page="perPage" first-text="First" prev-text="Prev" next-text="Next" last-text="Last"></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import DataTable from '../../components/DataTable.vue';
import { exportToCSV, exportToPDF } from '../../exportUtils';

const headerList = [
  { name: 'Sr.no', id: 'id' },
  { name: 'First Name', id: 'firstName' },
  { name: 'Last Name', id: 'lastName' },
  { name: 'Email', id: 'email' },
  { name: 'Media Name', id: 'mediaName' },
  { name: 'Media Introduction', id: 'mediaIntroduction' },
];

export default {
  name: 'ContactUsInquiries',
  components: {
    DataTable,
  },
  data() {
    return {
      headerList: headerList,
      currentPage: 1,
      perPage: 10,
      searchQuery: '',
      filteredMediaContacts: [],
    };
  },
  created() {
    // Fetch data when component is created
    this.$store.dispatch('mediacontact/fetchMediaContacts', null, { root: true });

    // Initialize filteredMediaContacts with the full list of media contacts
    this.$nextTick(() => {
      this.filteredMediaContacts = this.$store.state.mediacontact.mediacontacts;
    });
  },
  watch: {
    // Watch for changes in the searchQuery to filter data
    searchQuery() {
      this.filterMediaContacts(); // Re-filter when search query changes
    },
    // Watch for changes in the media contacts from Vuex to update filteredMediaContacts
    '$store.state.mediacontact.mediacontacts'(newMediaContacts) {
      this.filteredMediaContacts = newMediaContacts;
      this.filterMediaContacts(); // Re-filter after new data is fetched
    }
  },
  computed: {
    // Total rows in the filtered list
    totalRows() {
      return this.filteredMediaContacts.length;
    },
    // Paginated media contacts
    paginatedMediaContacts() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.filteredMediaContacts.slice(start, end);
    }
  },
  methods: {
    // Method to filter the media contacts based on the search query
    filterMediaContacts() {
      if (!this.searchQuery.trim()) {
        // If no search query, show all media contacts
        this.filteredMediaContacts = this.$store.state.mediacontact.mediacontacts;
      } else {
        // Filter based on the search query
        const query = this.searchQuery.toLowerCase();
        this.filteredMediaContacts = this.$store.state.mediacontact.mediacontacts.filter(mediaContact => {
          const fullName = `${mediaContact.firstName} ${mediaContact.lastName}`.toLowerCase();
          const isMatch =
            fullName.includes(query) ||
            (mediaContact.email && mediaContact.email.toLowerCase().includes(query)) ||
            (mediaContact.mediaName && mediaContact.mediaName.toLowerCase().includes(query)) ||
            (mediaContact.mediaIntroduction && mediaContact.mediaIntroduction.toLowerCase().includes(query));
          return isMatch;
        });
      }

      // Reset pagination to the first page after filtering
      this.currentPage = 1;
    },

    // Handle "Items per page" changes
    onItemsPerPageChange() {
        this.currentPage = 1; // Reset to first page when perPage changes
    },

    // Export to CSV
    handleExportCSV() {
      exportToCSV(this.paginatedMediaContacts, this.headerList);
    },

    // Export to PDF
    handleExportPDF() {
      exportToPDF(this.paginatedMediaContacts, this.headerList);
    }
  }
};
</script>


<style scoped>
.card-header {
  justify-content: space-between;
  align-items: center;
}

.d-flex {
  display: flex;
}

.gap-2 {
  gap: 10px;
}

.ml-2 {
  margin-left: 10px;
}

/* Adjustments for export buttons alignment */
.card-header .d-flex > .form-group {
  margin-right: 15px;
}

.card-body {
  padding: 0;
}

/* Ensure search and export buttons align without extra spacing */
.card-header .form-group {
  margin-bottom: 0;
}

.card-header .d-flex .btn {
  padding: 0.5rem 1rem;
  margin-left: 5px;
}

.card-header .d-flex .form-group input {
  width: 220px; /* Adjust to make input smaller */
}
</style>