<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Model Create</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form
          as="form"
          @submit="onFormSubmit"
          class="form"
          :validation-schema="schema"
          v-slot="{ errors }"
          enctype="multipart/form-data"
        >
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Model Name</label>
              <Field
                name="modelName"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.modelName }"
              />
              <div class="invalid-feedback">{{ errors.modelName }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Model Name</label>
              <Field
                name="modelName_ar"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.modelName_ar }"
              />
              <div class="invalid-feedback">{{ errors.modelName_ar }}</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-6">
              <label for="exampleInputEmail1">Model Description</label>
              <Field
                name="description"
                type="text"
                as="textarea"
                class="form-control"
                :class="{ 'is-invalid': errors.description }"
              />
              <div class="invalid-feedback">{{ errors.description }}</div>
            </div>
            <div class="col-md-6">
              <label for="exampleInputEmail1">Arabic Model Description</label>
              <Field
                name="description_ar"
                type="text"
                as="textarea"
                class="form-control"
                :class="{ 'is-invalid': errors.description_ar }"
              />
              <div class="invalid-feedback">{{ errors.description_ar }}</div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model Power</label>
              <Field
                name="power"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.power }"
              />
              <div class="invalid-feedback">{{ errors.power }}</div>
            </div>
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model Torque</label>
              <Field
                name="torque"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.torque }"
              />
              <div class="invalid-feedback">{{ errors.torque }}</div>
            </div>
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model Wheelbase</label>
              <Field
                name="wheelbase"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.wheelbase }"
              />
              <div class="invalid-feedback">{{ errors.wheelbase }}</div>
            </div>
            <div class="col-md-3">
              <label for="exampleInputEmail1">Model VR Link</label>
              <Field
                name="vr_link"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': errors.vr_link }"
              />
              <div class="invalid-feedback">{{ errors.vr_link }}</div>
            </div>
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Model Category</label>
            <Field
              name="modelCategory"
              as="select"
              class="form-control"
              :class="{ 'is-invalid': errors.modelCategory }"
            >
              <option value="" disabled>Select value</option>
              <option
                v-for="(category, index) in modelsCategories"
                :key="index"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </Field>

            <div class="invalid-feedback">{{ errors.modelCategory }}</div>
          </div>

          <div class="form-group">
            <label for="exampleInputEmail1">Design Type</label>
            <Field
              name="designType"
              as="select"
              class="form-control"
              v-model="formData.designType"
            >
              <option value="normal">Normal</option>
              <option value="advanced">Advanced</option>
            </Field>
          </div>

          <div class="form-group">
            <label for="image1">Image 1</label>
            <input
              type="file"
              id="image1"
              class="form-control"
              @change="onFileChange($event, 'global', 'image1')"
            />
            <div v-if="formData.image1_preview">
              <img
                :src="formData.image1_preview"
                alt="Image 1 Preview"
                class="img-thumbnail"
                height="200"
                width="230"
              />
            </div>
            <div class="form-group">
              <label for="image1_title">Image 1 Title (EN)</label>
              <Field
                id="image1_title"
                name="image1_title"
                v-model="formData.image1_title"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="image1_title_ar">Image 1 Title (AR)</label>
              <Field
                id="image1_title_ar"
                name="image1_title_ar"
                v-model="formData.image1_title_ar"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="image1_description">Image 1 Description (EN)</label>
              <Field
                id="image1_description"
                name="image1_description"
                v-model="formData.image1_description"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="image1_description_ar"
                >Image 1 Description (AR)</label
              >
              <Field
                id="image1_description_ar"
                name="image1_description_ar"
                v-model="formData.image1_description_ar"
                class="form-control"
              />
            </div>
          </div>

          <!-- Image 2 -->
          <div class="form-group">
            <label for="image2">Image 2</label>
            <input
              type="file"
              id="image2"
              class="form-control"
              @change="onFileChange($event, 'global', 'image2')"
            />
            <div v-if="formData.image2_preview">
              <img
                :src="formData.image2_preview"
                alt="Image 2 Preview"
                class="img-thumbnail"
                height="200"
                width="230"
              />
            </div>
            <div class="form-group">
              <label for="image2_title">Image 2 Title (EN)</label>
              <Field
                id="image2_title"
                name="image2_title"
                v-model="formData.image2_title"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="image2_title_ar">Image 2 Title (AR)</label>
              <Field
                id="image2_title_ar"
                name="image2_title_ar"
                v-model="formData.image2_title_ar"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="image2_description">Image 2 Description (EN)</label>
              <Field
                id="image2_description"
                name="image2_description"
                v-model="formData.image2_description"
                class="form-control"
              />
            </div>
            <div class="form-group">
              <label for="image2_description_ar"
                >Image 2 Description (AR)</label
              >
              <Field
                id="image2_description_ar"
                name="image2_description_ar"
                v-model="formData.image2_description_ar"
                class="form-control"
              />
            </div>
          </div>

          <div v-if="formData.designType === 'normal'">
            <div
              v-for="(section, index) in formData.normalSections"
              :key="`normal-section-${index}`"
            >
              <div class="form-group row">
                <!-- Section Title EN -->
                <div class="col-md-6">
                  <label :for="`normalSections[${index}].title_en`"
                    >Section Title (EN)</label
                  >
                  <Field
                    :name="`normalSections[${index}].title_en`"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        errors.normalSections &&
                        errors.normalSections[index]?.title_en,
                    }"
                    v-model="section.title_en"
                  />
                  <div
                    v-if="errors.normalSections?.[index]?.title_en"
                    class="invalid-feedback"
                  >
                    {{ errors.normalSections[index].title_en }}
                  </div>
                </div>

                <!-- Section Title AR -->
                <div class="col-md-6">
                  <label :for="`normalSections[${index}].title_ar`"
                    >Section Title (AR)</label
                  >
                  <Field
                    :name="`normalSections[${index}].title_ar`"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        errors.normalSections &&
                        errors.normalSections[index]?.title_ar,
                    }"
                    v-model="section.title_ar"
                  />
                  <div
                    v-if="errors.normalSections?.[index]?.title_ar"
                    class="invalid-feedback"
                  >
                    {{ errors.normalSections[index].title_ar }}
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <!-- Section Description EN -->
                <div class="col-md-6">
                  <label :for="`normalSections[${index}].description_en`"
                    >Section Description (EN)</label
                  >
                  <Field
                    :name="`normalSections[${index}].description_en`"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        errors.normalSections &&
                        errors.normalSections[index]?.description_en,
                    }"
                    v-model="section.description_en"
                  />
                  <div
                    v-if="errors.normalSections?.[index]?.description_en"
                    class="invalid-feedback"
                  >
                    {{ errors.normalSections[index].description_en }}
                  </div>
                </div>

                <!-- Section Description AR -->
                <div class="col-md-6">
                  <label :for="`normalSections[${index}].description_ar`"
                    >Section Description (AR)</label
                  >
                  <Field
                    :name="`normalSections[${index}].description_ar`"
                    type="text"
                    class="form-control"
                    :class="{
                      'is-invalid':
                        errors.normalSections &&
                        errors.normalSections[index]?.description_ar,
                    }"
                    v-model="section.description_ar"
                  />
                  <div
                    v-if="errors.normalSections?.[index]?.description_ar"
                    class="invalid-feedback"
                  >
                    {{ errors.normalSections[index].description_ar }}
                  </div>
                </div>
              </div>

              <div class="form-group row">
                <!-- Section Image EN -->
                <div class="col-md-6">
                  <label :for="`normalSections[${index}].image_en`">
                    Section Image (EN)
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    @change="
                      onFileChange($event, 'normalSections', 'image_en', index)
                    "
                  />
                  <!-- Image Preview EN -->
                  <div v-if="section.image_en_preview">
                    <img
                      height="200"
                      width="230"
                      :src="section.image_en_preview"
                      alt="Image Preview EN"
                      class="img-thumbnail"
                    />
                  </div>
                </div>

                <!-- Section Image AR -->
                <div class="col-md-6">
                  <label :for="`normalSections[${index}].image_ar`">
                    Section Image (AR)
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    @change="
                      onFileChange($event, 'normalSections', 'image_ar', index)
                    "
                  />
                  <!-- Image Preview AR -->
                  <div v-if="section.image_ar_preview">
                    <img
                      height="200"
                      width="230"
                      :src="section.image_ar_preview"
                      alt="Image Preview AR"
                      class="img-thumbnail"
                    />
                  </div>
                </div>
              </div>
            </div>

            <!-- Add More Item -->
            <button
              type="button"
              @click="addSection('normalSections')"
              class="btn btn-outline-primary"
            >
              Add More Item
            </button>
          </div>

          <div v-else-if="formData.designType === 'advanced'">
            <div
              v-for="(section, sectionName) in formData.advancedSections"
              :key="sectionName"
            >
              <div class="form-group">
                <h5>
                  {{ `Section ${sectionName.charAt(sectionName.length - 1)}` }}
                </h5>
                <div v-for="(subsection, index) in section" :key="index">
                  <div class="form-group row">
                    <div class="col-md-6">
                      <label
                        :for="`advancedSections.${sectionName}[${index}].title_en`"
                      >
                        Title (EN)
                      </label>
                      <Field
                        :name="`advancedSections.${sectionName}[${index}].title_en`"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid':
                            errors.advancedSections &&
                            errors.advancedSections[sectionName] &&
                            errors.advancedSections[sectionName][index] &&
                            errors.advancedSections[sectionName][index]
                              .title_en,
                        }"
                        v-model="subsection.title_en"
                      />
                      <div
                        v-if="
                          errors.advancedSections &&
                          errors.advancedSections[sectionName] &&
                          errors.advancedSections[sectionName][index] &&
                          errors.advancedSections[sectionName][index].title_en
                        "
                        class="invalid-feedback"
                      >
                        {{
                          errors.advancedSections[sectionName][index].title_en
                        }}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <label
                        :for="`advancedSections.${sectionName}[${index}].title_ar`"
                      >
                        Title (AR)
                      </label>
                      <Field
                        :name="`advancedSections.${sectionName}[${index}].title_ar`"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid':
                            errors.advancedSections &&
                            errors.advancedSections[sectionName] &&
                            errors.advancedSections[sectionName][index] &&
                            errors.advancedSections[sectionName][index]
                              .title_ar,
                        }"
                        v-model="subsection.title_ar"
                      />
                      <div
                        v-if="
                          errors.advancedSections &&
                          errors.advancedSections[sectionName] &&
                          errors.advancedSections[sectionName][index] &&
                          errors.advancedSections[sectionName][index].title_ar
                        "
                        class="invalid-feedback"
                      >
                        {{
                          errors.advancedSections[sectionName][index].title_ar
                        }}
                      </div>
                    </div>
                  </div>

                  <div class="form-group row">
                    <div class="col-md-6">
                      <label
                        :for="`advancedSections.${sectionName}[${index}].description_en`"
                      >
                        Description (EN)
                      </label>
                      <Field
                        :name="`advancedSections.${sectionName}[${index}].description_en`"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid':
                            errors.advancedSections &&
                            errors.advancedSections[sectionName] &&
                            errors.advancedSections[sectionName][index] &&
                            errors.advancedSections[sectionName][index]
                              .description_en,
                        }"
                        v-model="subsection.description_en"
                      />
                      <div
                        v-if="
                          errors.advancedSections &&
                          errors.advancedSections[sectionName] &&
                          errors.advancedSections[sectionName][index] &&
                          errors.advancedSections[sectionName][index]
                            .description_en
                        "
                        class="invalid-feedback"
                      >
                        {{
                          errors.advancedSections[sectionName][index]
                            .description_en
                        }}
                      </div>
                    </div>
                    <div class="col-md-6">
                      <label
                        :for="`advancedSections.${sectionName}[${index}].description_ar`"
                      >
                        Description (AR)
                      </label>
                      <Field
                        :name="`advancedSections.${sectionName}[${index}].description_ar`"
                        type="text"
                        class="form-control"
                        :class="{
                          'is-invalid':
                            errors.advancedSections &&
                            errors.advancedSections[sectionName] &&
                            errors.advancedSections[sectionName][index] &&
                            errors.advancedSections[sectionName][index]
                              .description_ar,
                        }"
                        v-model="subsection.description_ar"
                      />
                      <div
                        v-if="
                          errors.advancedSections &&
                          errors.advancedSections[sectionName] &&
                          errors.advancedSections[sectionName][index] &&
                          errors.advancedSections[sectionName][index]
                            .description_ar
                        "
                        class="invalid-feedback"
                      >
                        {{
                          errors.advancedSections[sectionName][index]
                            .description_ar
                        }}
                      </div>
                    </div>
                  </div>

                  <!-- Image Upload Section -->
                  <div class="form-group row">
                    <!-- Section Image EN -->
                    <div class="col-md-6">
                      <!-- <label :for="`normalSections[${index}].image_ar`">
                    Section Image (AR)
                  </label>
                  <input
                    type="file"
                    class="form-control"
                    @change="
                      onFileChange($event, 'normalSections', 'image_ar', index)
                    "
                  /> -->

                      <label
                        :for="`advancedSections[${sectionName}][${index}].image_en`"
                      >
                        Section Image (EN)
                      </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="
                          (event) =>
                            onFileChange(
                              event,
                              'advancedSections',
                              'image_en',
                              index,
                              sectionName
                            )
                        "
                      />
                      <div v-if="subsection.image_en_preview">
                        <img
                          height="200"
                          width="230"
                          :src="subsection.image_en_preview"
                          alt="Image Preview EN"
                          class="img-thumbnail"
                        />
                      </div>
                    </div>

                    <!-- Section Image AR -->
                    <div class="col-md-6">
                      <label
                        :for="`advancedSections[${sectionName}][${index}].image_ar`"
                      >
                        Section Image (AR)
                      </label>
                      <input
                        type="file"
                        class="form-control"
                        @change="
                          (event) =>
                            onFileChange(
                              event,
                              'advancedSections',
                              'image_ar',
                              index,
                              sectionName
                            )
                        "
                      />
                      <div v-if="subsection.image_ar_preview">
                        <img
                          height="200"
                          width="230"
                          :src="subsection.image_ar_preview"
                          alt="Image Preview AR"
                          class="img-thumbnail"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  @click="addSection(sectionName)"
                  class="btn btn-outline-primary mt-3"
                >
                  Add More Item
                </button>
              </div>
            </div>
          </div>

          <button type="submit" class="btn btn-danger my-2">Submit</button>
          <button
            type="button"
            @click="handleCancel"
            class="btn btn-outline-success my-2 cancel-btn"
          >
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";

import { mapGetters } from "vuex";
export default {
  name: "Model Add",
  components: {
    Form,
    Field,
  },
  setup() {
    const { setFieldValue } = useForm();
    return { setFieldValue };
  },
  data() {
    return {
      formData: {
        image1: null,
        image1_preview: null,
        image1_title: "",
        image1_title_ar: "",
        image1_description: "",
        image1_description_ar: "",
        image2: null,
        image2_preview: null,
        image2_title: "",
        image2_title_ar: "",
        image2_description: "",
        image2_description_ar: "",

        name: "",
        name_ar: "",
        description: "",
        description_ar: "",
        categoryId: 1,
        designType: "normal", // Default to 'normal'
        normalSections: [
          {
            title_en: "",
            title_ar: "",
            description_en: "",
            description_ar: "",
            image_en: null,
            image_ar: null,
          },
        ],
        advancedSections: {
          section1: [
            {
              title_en: "",
              title_ar: "",
              description_en: "",
              description_ar: "",
              image_en: null,
              image_ar: null,
            },
          ],
          section2: [
            {
              title_en: "",
              title_ar: "",
              description_en: "",
              description_ar: "",
              image_en: null,
              image_ar: null,
            },
          ],
          section3: [
            {
              title_en: "",
              title_ar: "",
              description_en: "",
              description_ar: "",
              image_en: null,
              image_ar: null,
            },
          ],
          section4: [
            {
              title_en: "",
              title_ar: "",
              description_en: "",
              description_ar: "",
              image_en: null,
              image_ar: null,
            },
          ],
        },
      },
      errors: {},
      touched: {},
      schema: yup.object().shape({
        power: yup.string().trim().required("Model Power is required"),
        torque: yup.string().trim().required("Model Torque is required"),
        wheelbase: yup.string().trim().required("Model Wheelbase is required"),
        vr_link: yup.string().trim(),
        modelName: yup.string().trim().required("Model Name is required"),
        modelName_ar: yup
          .string()
          .trim()
          .required("Arabic Model Name is required"),
        description: yup.string().trim().required("Description is required"),
        description_ar: yup
          .string()
          .trim()
          .required("Arabic Description is required"),
        modelCategory: yup
          .number()
          .required("Category is required")
          .positive("Category must be a positive number"),
        designType: yup
          .string()
          .oneOf(
            ["normal", "advanced"],
            "Design Type must be 'normal' or 'advanced'"
          )
          .required("Design Type is required"),

      }),
    };
  },
  computed: {
    ...mapGetters("modelsCategory", ["modelsCategories"]),
  },
  mounted() {
    this.$store.dispatch("modelsCategory/fetchModelsCategories", null, {
      root: true,
    });
  },

  methods: {
    onFileChange(event, sectionType, fieldName, index, sectionName = null) {
      console.log(sectionName, "sectionName", this.formData);
      const file = event.target.files[0];

      // Ensure the selected file is an image
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (e) => {
          // If the section is 'normalSections', store the file for the specific section
          if (sectionType === "normalSections") {
            this.formData.normalSections[index][fieldName] = file; // Store the file itself
            this.formData.normalSections[index][`${fieldName}_preview`] =
              e.target.result; // Store base64 preview
            console.log(this.formData, "plplplp");
          }
          // If the section is 'advancedSections', store the file for the specific section and field
          else if (sectionType === "advancedSections") {
            this.formData.advancedSections[sectionName][index][fieldName] =
              file; // Store the file itself
            this.formData.advancedSections[sectionName][index][
              `${fieldName}_preview`
            ] = e.target.result; // Store base64 preview
            console.log(this.formData, "pllplpp");
          }
          // Handle standalone image1 or image2
          else if (
            sectionType === "global" &&
            (fieldName === "image1" || fieldName === "image2")
          ) {
            this.formData[fieldName] = file; // Store the file itself
            this.formData[`${fieldName}_preview`] = e.target.result; // Store base64 preview
            console.log(this.formData, `${fieldName} updated`);
          }
          // console.log("Image preview", e.target.result); // Log base64 preview for debugging
        };
        reader.readAsDataURL(file);
        console.log(this.formData.advancedSections, "kkkk");
        // This loads the image as a base64 string (for preview)
      } else {
        alert("Please select a valid image file.");
      }
    },

    addSection(sectionType) {
      if (sectionType === "normalSections") {
        // Add a new section to normalSections with fields for image preview
        this.formData.normalSections.push({
          title_en: "",
          title_ar: "",
          description_en: "",
          description_ar: "",
          image_en: null,
          image_ar: null,
          image_en_preview: null, // Field for preview image in base64
          image_ar_preview: null, // Field for preview image in base64
        });
      } else if (this.formData.advancedSections[sectionType]) {
        // Add a new section to the specified advancedSections with fields for image preview
        this.formData.advancedSections[sectionType].push({
          title_en: "",
          title_ar: "",
          description_en: "",
          description_ar: "",
          image_en: null,
          image_ar: null,
          image_en_preview: null, // Field for preview image in base64
          image_ar_preview: null, // Field for preview image in base64
        });
      } else {
        console.error(`Unknown section type: ${sectionType}`);
      }
    },

    // When form is submitted, handle image files and append them to FormData
    onFormSubmit(values) {
      console.log(values, "Form Submission Values");

      const formData = new FormData();

      if (!this.formData.image1) {
        this.$swal({
          position: "center",
          icon: "error", // Changed to 'error' because image1 is missing
          title: "Error: Image 1 is required.",
          showConfirmButton: false,
          timer: 2000,
        });
        return; // Stop the submission process
      }

      // Check if image2 exists
      if (!this.formData.image2) {
        this.$swal({
          position: "center",
          icon: "error", // Changed to 'error' because image2 is missing
          title: "Error: Image 2 is required.",
          showConfirmButton: false,
          timer: 2000,
        });
        return; // Stop the submission process
      }

      if (this.formData.designType === "normal") {
        console.log("Checking normal designType...");

        // Check that at least one normal section has valid image data
        const validNormalSection = this.formData.normalSections.some(
          (section) => {
            return section.image_en && section.image_ar;
          }
        );

        if (!validNormalSection) {
          this.$swal({
            position: "center",
            icon: "error", // 'error' because validation failed
            title: "Error: At least one normal section must have valid images.",
            showConfirmButton: false,
            timer: 2000,
          });
          return; // Stop the submission process
        }
      } else if (values.designType === "advanced") {
        // Check for section 1: At least 4 items with valid images
        const validSection1 =
          this.formData.advancedSections.section1.filter((section) => {
            return section.image_en && section.image_ar;
          }).length >= 4;

        if (!validSection1) {
          this.$swal({
            position: "center",
            icon: "error", // 'error' for missing valid images in section 1
            title:
              "Error: Section 1 must have at least 4 items with valid images.",
            showConfirmButton: false,
            timer: 2000,
          });
          return; // Stop the submission process
        }

        // Check for section 2: At least 2 items with valid images
        const validSection2 =
          this.formData.advancedSections.section2.filter((section) => {
            return section.image_en && section.image_ar;
          }).length >= 2;

        if (!validSection2) {
          this.$swal({
            position: "center",
            icon: "error", // 'error' for missing valid images in section 2
            title:
              "Error: Section 2 must have at least 2 items with valid images.",
            showConfirmButton: false,
            timer: 2000,
          });
          return; // Stop the submission process
        }

        // Check for section 3: At least 3 items with valid images
        const validSection3 =
          this.formData.advancedSections.section3.filter((section) => {
            return section.image_en && section.image_ar;
          }).length >= 3;

        if (!validSection3) {
          this.$swal({
            position: "center",
            icon: "error", // 'error' for missing valid images in section 3
            title:
              "Error: Section 3 must have at least 3 items with valid images.",
            showConfirmButton: false,
            timer: 2000,
          });
          return; // Stop the submission process
        }

        // Check for section 4: At least 2 items with valid images
        const validSection4 =
          this.formData.advancedSections.section4.filter((section) => {
            return section.image_en && section.image_ar;
          }).length >= 2;

        if (!validSection4) {
          this.$swal({
            position: "center",
            icon: "error", // 'error' for missing valid images in section 4
            title:
              "Error: Section 4 must have at least 2 items with valid images.",
            showConfirmButton: false,
            timer: 2000,
          });
          return; // Stop the submission process
        }
      }
      // Append model fields (e.g., name, description, category)
      const modelFields = {
        name: values.modelName,
        name_ar: values.modelName_ar,
        description: values.description,
        description_ar: values.description_ar,
        categoryId: values.modelCategory,
        power: values.power,
        torque: values.torque,
        wheelbase: values.wheelbase,
        vr_link: values.vr_link,
        designType: values.designType, // Include designType
      };

      for (const [key, value] of Object.entries(modelFields)) {
        formData.append(key, value);
      }

      // Append image1 and its metadata
      if (this.formData.image1) {
        formData.append("image1", this.formData.image1);
        formData.append("image1_title", this.formData.image1_title || "");
        formData.append("image1_title_ar", this.formData.image1_title_ar || "");
        formData.append(
          "image1_description",
          this.formData.image1_description || ""
        );
        formData.append(
          "image1_description_ar",
          this.formData.image1_description_ar || ""
        );
      }

      // Append image2 and its metadata
      if (this.formData.image2) {
        formData.append("image2", this.formData.image2);
        formData.append("image2_title", this.formData.image2_title || "");
        formData.append("image2_title_ar", this.formData.image2_title_ar || "");
        formData.append(
          "image2_description",
          this.formData.image2_description || ""
        );
        formData.append(
          "image2_description_ar",
          this.formData.image2_description_ar || ""
        );
      }

      if (this.formData.designType === "normal") {
        this.formData.normalSections.forEach((section, index) => {
          // Append general section fields
          [
            "title_en",
            "title_ar",
            "description_en",
            "description_ar",
            "image_en",
            "image_ar",
          ].forEach((key) => {
            formData.append(
              `normalSections[${index}][${key}]`,
              section[key] || ""
            );
          });
        });
      } else if (values.designType === "advanced") {
        Object.entries(this.formData.advancedSections).forEach(
          ([sectionName, sections]) => {
            sections.forEach((section, index) => {
              [
                "title_en",
                "title_ar",
                "description_en",
                "description_ar",
                "image_en",
                "image_ar",
              ].forEach((key) => {
                formData.append(
                  `advancedSections[${sectionName}][${index}][${key}]`,
                  section[key] || "" // If value is undefined or null, append an empty string
                );
              });
            });
          }
        );
      }

      // Submit form data (can send to backend API or other service)
      this.$store
        .dispatch("models/post", formData)
        .then((res) => {
          // const me = res
          if (res === 200) {
            this.$swal({
              position: "center",
              icon: "success",
              title: "Model has been added",
              showConfirmButton: false,
              timer: 2000,
            });
            this.$router.push("/models");  // Navigate to models page
          } else {
            // console.log(me,'dfsdfsdfsdfsdfsdfsdfsdfsdfdsfsdfsdfdsfdsfsd')
            this.$swal({
            position: "center",
            icon: "error", // 'error' for missing valid images in section 4
            title:
            "error occured",
            showConfirmButton: false,
            timer: 2000,
          });
          }
        })
        .catch((error) => {
          console.error("Error adding model:", error);
          this.$swal("Error!", "There was an issue adding the model. maybe the model name already exist,  or some data not passed", "error");
        });
    },
  },
};
</script>
