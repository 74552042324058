<template>
  <div class="card my-4 mx-4">
    <div class="card-header pb-0">
      <h6>Edit Home CMS</h6>
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="p-3">
        <Form as="form" class="form" @submit="onSubmit" :initial-values="initialData" :validation-schema="schema"
          enctype="multipart/form-data" v-slot="{ errors }" v-if="homeObj">
          <div class="form-group">
            <h4>Header Logo</h4>
            <button class="btn btn-outline-danger" @click.prevent="() => this.$refs.logo.click()">
              Image Upload
            </button>
            <input type="file" ref="logo" @change="event => onFileChange(event, 'logo')" accept="image/*"
              style="display: none" />
            <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field>
            <div v-if="logoData">
              <h5>Preview Header Logo :</h5>
              <img :src="logoData" alt="Image Preview" height="200" width="230" />
            </div>
            <div v-if="homeObj && !logoData">
              <h5>Preview Header Logo:</h5>
              <img :src="imageApi + homeObj.logo" alt="Image Preview" height="200" width="230" />
            </div>
          </div>

          <div>
            <h3>Banner Section 1</h3>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="banner_image1_title">Banner Section 1 Title</label>
                <Field name="banner_image1_title" type="text" class="form-control" v-model="homeObj.banner_image1_title"
                  :class="{ 'is-invalid': errors.banner_image1_title }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image1_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Banner Section 1 Title</label>
                <Field name="banner_image1_title_ar" type="text" class="form-control"
                  v-model="homeObj.banner_image1_title_ar" :class="{ 'is-invalid': errors.banner_image1_title_ar }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image1_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="banner_image1_description">Banner Section 1 Description</label>
                <Field name="banner_image1_description" type="text" class="form-control"
                  v-model="homeObj.banner_image1_description"
                  :class="{ 'is-invalid': errors.banner_image1_description }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image1_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Banner Section 1 Description</label>
                <Field name="banner_image1_description_ar" type="text" class="form-control"
                  v-model="homeObj.banner_image1_description_ar"
                  :class="{ 'is-invalid': errors.banner_image1_description_ar }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image1_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="banner_image1_button1">Banner Section 1 Button 1 Text</label>
                <Field name="banner_image1_button1" type="text" class="form-control"
                  v-model="homeObj.banner_image1_button1" :class="{ 'is-invalid': errors.banner_image1_button1 }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image1_button1 }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Banner Section 1 Button 2 Text</label>
                <Field name="banner_image1_button2" type="text" class="form-control"
                  v-model="homeObj.banner_image1_button2" :class="{ 'is-invalid': errors.banner_image1_button2 }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image1_button2 }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="banner_image1_button1_url">Banner Section 1 Button 1 URL</label>
                <Field name="banner_image1_button1_url" type="text" class="form-control"
                  v-model="homeObj.banner_image1_button1_url"
                  :class="{ 'is-invalid': errors.banner_image1_button1_url }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image1_button1_url }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Banner Section 1 Button 2 URL</label>
                <Field name="banner_image1_button2_url" type="text" class="form-control"
                  v-model="homeObj.banner_image1_button2_url"
                  :class="{ 'is-invalid': errors.banner_image1_button2_url }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image1_button2_url }}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <h4>Banner Image Slider 1</h4>
            <button class="btn btn-outline-danger" @click.prevent="() => this.$refs.banner_image1.click()">
              Image Upload
            </button>
            <input type="file" ref="banner_image1" @change="event => onFileChange(event, 'banner_image1')"
              accept="image/*" style="display: none" />
            <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field>
            <div v-if="banner_image1Data">
              <h5>Preview Banner Image 1 :</h5>
              <img :src="banner_image1Data" alt="Image Preview" height="200" width="230" />
            </div>
            <div v-if="homeObj && !banner_image1Data">
              <h5>Preview Banner Image:</h5>
              <img :src="imageApi + homeObj.banner_image1" alt="Image Preview" height="200" width="230" />
            </div>
          </div>

          <div>
            <h3>Banner Section 2</h3>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="banner_image2_title">Banner Section 2 Title</label>
                <Field name="banner_image2_title" type="text" class="form-control" v-model="homeObj.banner_image2_title"
                  :class="{ 'is-invalid': errors.banner_image2_title }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image2_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Banner Section 2 Title</label>
                <Field name="banner_image2_title_ar" type="text" class="form-control"
                  v-model="homeObj.banner_image2_title_ar" :class="{ 'is-invalid': errors.banner_image2_title_ar }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image2_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="banner_image2_description">Banner Section 2 Description</label>
                <Field name="banner_image2_description" type="text" class="form-control"
                  v-model="homeObj.banner_image2_description"
                  :class="{ 'is-invalid': errors.banner_image2_description }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image2_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Banner Section 2 Description</label>
                <Field name="banner_image2_description_ar" type="text" class="form-control"
                  v-model="homeObj.banner_image2_description_ar"
                  :class="{ 'is-invalid': errors.banner_image2_description_ar }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image2_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="banner_image2_button1">Banner Section 2 Button 1 Text</label>
                <Field name="banner_image2_button1" type="text" class="form-control"
                  v-model="homeObj.banner_image2_button1" :class="{ 'is-invalid': errors.banner_image2_button1 }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image2_button1 }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Banner Section 2 Button 2 Text</label>
                <Field name="banner_image2_button2" type="text" class="form-control"
                  v-model="homeObj.banner_image2_button2" :class="{ 'is-invalid': errors.banner_image2_button2 }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image2_button2 }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="banner_image2_button1_url">Banner Section 2 Button 1 URL</label>
                <Field name="banner_image2_button1_url" type="text" class="form-control"
                  v-model="homeObj.banner_image2_button1_url"
                  :class="{ 'is-invalid': errors.banner_image2_button1_url }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image2_button1_url }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Banner Section 2 Button 2 URL</label>
                <Field name="banner_image2_button2_url" type="text" class="form-control"
                  v-model="homeObj.banner_image2_button2_url"
                  :class="{ 'is-invalid': errors.banner_image2_button2_url }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image2_button2_url }}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <h4>Banner Image Slider 2</h4>
            <button class="btn btn-outline-danger" @click.prevent="() => this.$refs.banner_image2.click()">
              Image Upload
            </button>
            <input type="file" ref="banner_image2" @change="event => onFileChange(event, 'banner_image2')"
              accept="image/*" style="display: none" />
            <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field>
            <div v-if="banner_image2Data">
              <h5>Preview Banner Image 2 :</h5>
              <img :src="banner_image2Data" alt="Image Preview" height="200" width="230" />
            </div>
            <div v-if="homeObj && !banner_image2Data">
              <h5>Preview Banner Image 2:</h5>
              <img :src="imageApi + homeObj.banner_image2" alt="Image Preview" height="200" width="230" />
            </div>
          </div>

          <div>
            <h3>Banner Section 3</h3>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="banner_image3_title">Banner Section 3 Title</label>
                <Field name="banner_image3_title" type="text" class="form-control" v-model="homeObj.banner_image3_title"
                  :class="{ 'is-invalid': errors.banner_image3_title }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image3_title }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Banner Section 3 Title</label>
                <Field name="banner_image3_title_ar" type="text" class="form-control"
                  v-model="homeObj.banner_image3_title_ar" :class="{ 'is-invalid': errors.banner_image3_title_ar }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image3_title_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="banner_image3_description">Banner Section 3 Description</label>
                <Field name="banner_image3_description" type="text" class="form-control"
                  v-model="homeObj.banner_image3_description"
                  :class="{ 'is-invalid': errors.banner_image3_description }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image3_description }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Arabic Banner Section 3 Description</label>
                <Field name="banner_image3_description_ar" type="text" class="form-control"
                  v-model="homeObj.banner_image3_description_ar"
                  :class="{ 'is-invalid': errors.banner_image3_description_ar }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image3_description_ar }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="banner_image3_button1">Banner Section 3 Button 1 Text</label>
                <Field name="banner_image3_button1" type="text" class="form-control"
                  v-model="homeObj.banner_image3_button1" :class="{ 'is-invalid': errors.banner_image3_button1 }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image3_button1 }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Banner Section 3 Button 2 Text</label>
                <Field name="banner_image3_button2" type="text" class="form-control"
                  v-model="homeObj.banner_image3_button2" :class="{ 'is-invalid': errors.banner_image3_button2 }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image3_button2 }}
                </div>
              </div>
            </div>
            <div class="form-group row">
              <div class="col-md-6">
                <label for="banner_image3_button1_url">Banner Section 3 Button 1 URL</label>
                <Field name="banner_image3_button1_url" type="text" class="form-control"
                  v-model="homeObj.banner_image3_button1_url"
                  :class="{ 'is-invalid': errors.banner_image3_button1_url }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image3_button1_url }}
                </div>
              </div>
              <div class="col-md-6">
                <label for="">Banner Section 3 Button 2 URL</label>
                <Field name="banner_image3_button2_url" type="text" class="form-control"
                  v-model="homeObj.banner_image3_button2_url"
                  :class="{ 'is-invalid': errors.banner_image3_button2_url }" />

                <div class="invalid-feedback">
                  {{ errors.banner_image3_button2_url }}
                </div>
              </div>
            </div>
          </div>

          <div class="form-group">
            <h4>Banner Image Slider 3</h4>
            <button class="btn btn-outline-danger" @click.prevent="() => this.$refs.banner_image3.click()">
              Image Upload
            </button>
            <input type="file" ref="banner_image3" @change="event => onFileChange(event, 'banner_image3')"
              accept="image/*" style="display: none" />
            <Field name="image" v-slot="{ errors }">
              <div v-if="errors.length">{{ errors[0] }}</div>
            </Field>
            <div v-if="banner_image3Data">
              <h5>Preview Banner Image 3 :</h5>
              <img :src="banner_image3Data" alt="Image Preview" height="200" width="230" />
            </div>
            <div v-if="homeObj && !banner_image3Data">
              <h5>Preview Banner Image 3:</h5>
              <img :src="imageApi + homeObj.banner_image3" alt="Image Preview" height="200" width="230" />
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-5">
              <label for="">Instagram Link</label>
              <Field name="instagram_link" type="text" class="form-control" v-model="homeObj.instagram_link"
                :class="{ 'is-invalid': errors.instagram_link }" />

              <div class="invalid-feedback">{{ errors.instagram_link }}</div>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-md-5">
              <label for="">Instagram Link</label>
              <Field name="instagram_link" type="text" class="form-control" v-model="homeObj.instagram_link"
                :class="{ 'is-invalid': errors.instagram_link }" />

              <div class="invalid-feedback">{{ errors.instagram_link }}</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-5">
              <label for="">Facebook Link</label>
              <Field name="facebook_link" type="text" class="form-control" v-model="homeObj.facebook_link"
                :class="{ 'is-invalid': errors.facebook_link }" />

              <div class="invalid-feedback">{{ errors.facebook_link }}</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-md-5">
              <label for="">Linkedin Link</label>
              <Field name="linkedin_link" type="text" class="form-control" v-model="homeObj.linkedin_link"
                :class="{ 'is-invalid': errors.linkedin_link }" />

              <div class="invalid-feedback">{{ errors.linkedin_link }}</div>
            </div>
          </div>
          <button type="submit" class="btn btn-success">Submit</button>
          <button type="button" @click="handleCancel" class="btn btn-outline-success cancel-btn">
            Cancel
          </button>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Form, useForm } from "vee-validate";
import * as yup from "yup";
import { mapGetters } from "vuex";
import imageApi from "../../api/imageapi";
import _ from "lodash";
export default {
  name: "After Sales Service",
  components: {
    Field,
    Form,
  },
  data: () => {
    const { setFieldValue } = useForm();
    const schema = yup.object().shape({
      instagram_link: yup.string().trim().required("Instagram Link is Required"),
      facebook_link: yup.string().trim().required("Facebook Link is Required"),
      linkedin_link: yup.string().trim().required("Linkedin Link is Required"),
      // banner_image1_title: yup
      //   .string()
      //   .trim()
      //   .required("Banner Image 1 Title Link is Required"),
      // banner_image1_title_ar: yup
      //   .string()
      //   .trim()
      //   .required("Banner Image 1 Title  Arabic is Required"),
      // banner_image1_description: yup
      //   .string()
      //   .trim()
      //   .required("Banner Image 1 Description is Required"),
      // banner_image1_description_ar: yup
      //   .string()
      //   .trim()
      //   .required("Banner Image 1 Description Arabic is Required"),
      banner_image1_button1: yup
        .string()
        .trim()
        .required("Banner Image 1 Button 1 Title  is Required"),
      banner_image1_button2: yup
        .string()
        .trim()
        .required("Banner Image 1 Button 2  is Required"),
      banner_image1_button1_url: yup
        .string()
        .trim()
        .required("Banner Image 1 Button 1 URL is Required"),
      banner_image1_button2_url: yup
        .string()
        .trim()
        .required("Banner Image 1 Button 2 URL is Required"),
      // banner_image2_title: yup
      //   .string()
      //   .trim()
      //   .required("Banner Image 2 Title is Required"),
      // banner_image2_title_ar: yup
      //   .string()
      //   .trim()
      //   .required("Banner Image 2 Title Arabic is Required"),
      // banner_image2_description: yup
      //   .string()
      //   .trim()
      //   .required("Banner Image 2 Description is Required"),
      // banner_image2_description_ar: yup
      //   .string()
      //   .trim()
      //   .required("Banner Image 2 Description Arabic is Required"),
      banner_image2_button1: yup
        .string()
        .trim()
        .required("Banner Image 2 Button 1 Title is Required"),

      banner_image2_button2: yup
        .string()
        .trim()
        .required("Banner Image 2 Button 2  is Required"),
      banner_image2_button1_url: yup
        .string()
        .trim()
        .required("Banner Image 2 Button 1 URL is Required"),
      banner_image2_button2_url: yup
        .string()
        .trim()
        .required("Banner Image 2 Button 2 URL is Required"),
      // banner_image3_title: yup
      //   .string()
      //   .trim()
      //   .required("Banner Image 3 Title  Required"),
      // banner_image3_title_ar: yup
      //   .string()
      //   .trim()
      //   .required("Banner Image 3 Title Arabic is Required"),
      // banner_image3_description: yup
      //   .string()
      //   .trim()
      //   .required("Banner Image 3 Description is Required"),
      // banner_image3_description_ar: yup
      //   .string()
      //   .trim()
      //   .required("Banner Image 3 Description Arabic is Required"),
      banner_image3_button1: yup
        .string()
        .trim()
        .required("Banner Image 3 Button 1 Title is Required"),
      banner_image3_button2: yup
        .string()
        .trim()
        .required("Banner Image 3 Button 2 Title is Required"),
      banner_image3_button1_url: yup
        .string()
        .trim()
        .required("Banner Image 3 Button 1 URL is Required"),
      banner_image3_button2_url: yup
        .string()
        .trim()
        .required("Banner Image 3 Button 2 URL is Required"),
    });

    const initialData = {
      instagram_link: "",
      facebook_link: "",
      linkedin_link: "",
      banner_image1_title: "",
      banner_image1_title_ar: "",
      banner_image1_description: "",
      banner_image1_description_ar: "",
      banner_image1_button1: "",
      banner_image1_button2: "",
      banner_image2_title: "",
      banner_image2_title_ar: "",
      banner_image2_description: "",
      banner_image2_description_ar: "",
      banner_image2_button1: "",
      banner_image2_button2: "",
      banner_image3_title: "",
      banner_image3_title_ar: "",
      banner_image3_description: "",
      banner_image3_description_ar: "",
      banner_image3_button1: "",
      banner_image3_button2: "",
      banner_image1_button1_url: "",
      banner_image1_button2_url: "",
      banner_image2_button1_url: "",
      banner_image2_button2_url: "",
      banner_image3_button1_url: "",
      banner_image3_button2_url: "",
    };
    return {
      initialData,
      schema,
      setFieldValue,
      imageApi,
      banner_image1: null,
      banner_image2: null,
      banner_image3: null,
      logo: null,
      banner_image1Data: null,
      banner_image2Data: null,
      banner_image3Data: null,
      logoData: null,
    };
  },
  computed: {
    ...mapGetters("home", ["homeObj", "isLoading"]),
  },
  mounted() {
    this.fetchDetail();

    let initData = this.homeObj ? _.clone(this.homeObj) : {};

    this.initialData = this.homeObj
      ? {
        ...initData,
      }
      : this.initialData;
  },
  methods: {
    fetchDetail() {
      try {
        this.$store.dispatch("home/fetchHomeDetail", 1);
      } catch (err) {
        console.log(err);
      }
    },
    onSubmit(values) {
      const obj = {
        ...values,
        banner_image1: this.banner_image1,
        banner_image2: this.banner_image2,
        banner_image3: this.banner_image3,
        logo: this.logo,
      };

      let formData = new FormData();

      formData.append("instagram_link", obj.instagram_link);
      formData.append("facebook_link", obj.facebook_link);
      formData.append("linkedin_link", obj.linkedin_link);
      formData.append(
        "banner_image1_button1_url",
        obj.banner_image1_button1_url
      );
      formData.append(
        "banner_image1_button2_url",
        obj.banner_image1_button2_url
      );
      formData.append(
        "banner_image2_button1_url",
        obj.banner_image2_button1_url
      );
      formData.append(
        "banner_image2_button2_url",
        obj.banner_image2_button2_url
      );
      formData.append(
        "banner_image3_button1_url",
        obj.banner_image3_button1_url
      );
      formData.append(
        "banner_image3_button2_url",
        obj.banner_image3_button2_url
      );
      formData.append("banner_image1_title", obj.banner_image1_title);
      formData.append("banner_image1_title_ar", obj.banner_image1_title_ar);
      formData.append(
        "banner_image1_description",
        obj.banner_image1_description
      );
      formData.append(
        "banner_image1_description_ar",
        obj.banner_image1_description_ar
      );
      formData.append("banner_image1_button1", obj.banner_image1_button1);
      formData.append("banner_image1_button2", obj.banner_image1_button2);
      formData.append("banner_image2_title", obj.banner_image2_title);
      formData.append("banner_image2_title_ar", obj.banner_image2_title_ar);
      formData.append(
        "banner_image2_description",
        obj.banner_image2_description
      );
      formData.append(
        "banner_image2_description_ar",
        obj.banner_image2_description_ar
      );
      formData.append("banner_image2_button1", obj.banner_image2_button1);
      formData.append("banner_image2_button2", obj.banner_image2_button2);
      formData.append("banner_image3_title", obj.banner_image3_title);
      formData.append("banner_image3_title_ar", obj.banner_image3_title_ar);
      formData.append(
        "banner_image3_description",
        obj.banner_image3_description
      );
      formData.append(
        "banner_image3_description_ar",
        obj.banner_image3_description_ar
      );
      formData.append("banner_image3_button1", obj.banner_image3_button1);
      formData.append("banner_image3_button2", obj.banner_image3_button2);
      formData.append("banner_image1", obj.banner_image1);
      formData.append("banner_image2", obj.banner_image2);
      formData.append("banner_image3", obj.banner_image3);
      formData.append("logo", obj.logo);

      this.$store.dispatch("home/put", formData);

      this.$swal({
        position: "center",
        icon: "success",
        title: "Home Page has been Updated",
        showConfirmButton: false,
        timer: 2000,
      });

      this.fetchDetail();
      this.$router.push("/cms");
    },
    handleCancel() {
      this.$router.push("/cms");
    },
    onFileChange(event, title) {
      const file = event.target.files[0];

      if (title == "banner_image1") {
        this.banner_image1 = event.target.files[0];
      } else if (title == "banner_image2") {
        this.banner_image2 = event.target.files[0];
      } else if (title == "banner_image3") {
        this.banner_image3 = event.target.files[0];
      } else if (title == "logo") {
        this.logo = event.target.files[0];
      }

      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = e => {
          if (title == "banner_image1") {
            this.banner_image1Data = e.target.result;
          } else if (title == "banner_image2") {
            this.banner_image2Data = e.target.result;
          } else if (title == "banner_image3") {
            this.banner_image3Data = e.target.result;
          } else if (title == "logo") {
            this.logoData = e.target.result;
          }
        };

        reader.readAsDataURL(file);
      } else {
        alert("Please select a valid image file.");
      }
    },
  },
};
</script>
